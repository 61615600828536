import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()

export class HeaderInterceptor implements HttpInterceptor {
    
    constructor(){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({
            headers: this.getHeadersConfig()
        });
        return next.handle(modifiedReq);
    }

    getHeadersConfig():HttpHeaders{
        let headers = new HttpHeaders()
        headers = headers.append('X-Frame-Options', 'DENY');
        headers = headers.append('X-Content-Type-Options', 'nosniff');
        headers = headers.append('X-Permitted-Cross-Domain-Policies', 'none');
        headers = headers.append('Referrer-Policy', 'no-referrer');
        headers = headers.append('Clear-Site-Data', '"cache","cookies","storage"');
        headers = headers.append('Cross-Origin-Embedder-Policy', 'require-corp');
        headers = headers.append('Cross-Origin-Opener-Policy', 'same-origin');
        headers = headers.append('Cross-Origin-Resource-Policy', 'same-origin');
        headers = headers.append('Content-Security-Policy', 'script-src "self"');
        headers = headers.append('Permissions-Policy', 'geolocation "self" ; camera "none"');
        headers = headers.append('Cache-Control', 'public, max-age=31536000');
        
        return headers;
    }
}