import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InserirRespostaNpsInstitucionalSignature } from 'src/app/core/models/nps/inserir-resposta-nps-institucional-signature.model';
import { PesquisaNpsService } from 'src/app/core/services/pesquisa-nps.service';

@Component({
  selector: 'app-pesquisa-nps',
  templateUrl: './pesquisa-nps.component.html',
  styleUrls: ['./pesquisa-nps.component.scss']
})
export class PesquisaNpsComponent implements OnInit {

  public Id: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _pesquisaNpsService: PesquisaNpsService

  ) {
    setTimeout(() => this.spinner.show(), 25)
    this.activatedRoute.queryParams.subscribe(params => {
      this.Id = params['id'];
    });
  }

  ngOnInit(): void {

    if (this.Id)
      this._pesquisaNpsService.RegistrarPesquisaNPS(new InserirRespostaNpsInstitucionalSignature(this.Id)).subscribe();

    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
  }
}
