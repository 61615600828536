import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingModalComponent implements OnInit, OnDestroy {

  constructor(
    private spinner: NgxSpinnerService,
    private _loadingService: LoadingService,
    private modal: MatDialog,
    private _blurService: BlurService
  ) { }

  ngOnDestroy(): void {
    this._blurService.setBlurBackground(false)
    this._loadingService.setLoading(false)
    this.modal.closeAll()
  }

  ngOnInit(): void {
    this._blurService.setBlurBackground(true)
    this._loadingService.setLoading(true)
    setTimeout(() => this.spinner.show(), 25)
  }
}
