import { CommonModule, registerLocaleData } from '@angular/common'
import { LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AcessoRapidoComponent } from './components/acesso-rapido/acesso-rapido.component'
import { ArtigosComponent } from './components/artigos/artigos.component'
import { BannerVideoSegurancaComponent } from './components/banner-video-seguranca/banner-video-seguranca.component'
import { BannerAtendimentoComponent } from './components/banners/banner-atendimento/banner-atendimento.component'
import { BannerBoxExpansivelComponent } from './components/banners/banner-box-expansivel/banner-box-expansivel.component'
import { BannerCarrosselComponent } from './components/banners/banner-carrossel/banner-carrossel.component'
import { BannerComBotaoComponent } from './components/banners/banner-com-botao/banner-com-botao.component'
import { BannerConteudoAlinhadoIconsComponent } from './components/banners/banner-conteudo-alinhado-icons/banner-conteudo-alinhado-icons.component'
import { BannerConteudoAlinhadoComponent } from './components/banners/banner-conteudo-alinhado/banner-conteudo-alinhado.component'
import { BannerConteudoCruzadoComponent } from './components/banners/banner-conteudo-cruzado/banner-conteudo-cruzado.component'
import { BannerFlipbookDaycovalComponent } from './components/banners/banner-flipbook-daycoval/banner-flipbook-daycoval.component'
import { BannerInformacoesProdutoDaypayComponent } from './components/banners/banner-informacoes-produto-daypay/banner-informacoes-produto-daypay.component'
import { BannerInformacoesProdutoComponent } from './components/banners/banner-informacoes-produto/banner-informacoes-produto.component'
import { BannerLgMeioComponent } from './components/banners/banner-lg-meio/banner-lg-meio.component'
import { BannerLgComponent } from './components/banners/banner-lg/banner-lg.component'
import { BannerLinksAlinhadoComponent } from './components/banners/banner-links-alinhado/banner-links-alinhado.component'
import { BannerMdComponent } from './components/banners/banner-md/banner-md.component'
import { BannerVideoComponent } from './components/banners/banner-video/banner-video.component'
import { BarraSimuladorRendaFixaComponent } from './components/barra-simulador-renda-fixa/barra-simulador-renda-fixa.component'
import { BlogComponent } from './components/blog/blog.component'
import { BoxAcompanheSeuAtendimentoComponent } from './components/box-acompanhe-seu-atendimento/box-acompanhe-seu-atendimento.component'
import { BoxAtendimentoComponent } from './components/box-atendimento/box-atendimento.component'
import { BoxConhecaCartoesComponent } from './components/box-conheca-cartoes/box-conheca-cartoes.component'
import { BoxConhecaVantagensCarrosselComponent } from './components/box-conheca-vantagens-carrossel/box-conheca-vantagens-carrossel.component'
import { BoxConsultaCobradoraComponent } from './components/box-consulta-cobradora/box-consulta-cobradora.component'
import { BoxConteudoLinksComponent } from './components/box-conteudo-links/box-conteudo-links.component'
import { BoxDocumentoComponent } from './components/box-documento/box-documento.component'
import { BoxLojaComponent } from './components/box-loja/box-loja.component'
import { BoxSobreCartaoComponent } from './components/box-sobre-cartao/box-sobre-cartao.component'
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { BtnPedirCartaoComponent } from './components/btn-pedir-cartao/btn-pedir-cartao.component'
import { CanaisAtendimentoComponent } from './components/canais-atendimento/canais-atendimento.component'
import { CardsInformativosComponent } from './components/cards-informativos/cards-informativos.component'
import { CarrosselConteudosComponent } from './components/carrossel-conteudos/carrossel-conteudos.component'
import { ContatoAbrirContaComponent } from './components/contatos/contato-abrir-conta/contato-abrir-conta.component'
import { ContatoCambioComponent } from './components/contatos/contato-cambio/contato-cambio.component'
import { ContatoInvestimentosPjComponent } from './components/contatos/contato-investimentos-pj/contato-investimentos-pj.component'
import { ContatoInvestimentosComponent } from './components/contatos/contato-investimentos/contato-investimentos.component'
import { ContatoLojaComponent } from './components/contatos/contato-loja/contato-loja.component'
import { ContatoTesourariaComponent } from './components/contatos/contato-tesouraria/contato-tesouraria.component'
import { ConverseDayaneComponent } from './components/converse-dayane/converse-dayane.component'
import { DayReCaptchaModalComponent } from './components/day-re-captcha-modal/day-re-captcha-modal.component'
import { DayReCaptchaComponent } from './components/day-re-captcha/day-re-captcha.component'
import { DayconnectNaWebComponent } from './components/dayconnect-na-web/dayconnect-na-web.component'
import { DetalheGenericoFooterComponent } from './components/detalhe-generico/detalhe-generico-footer/detalhe-generico-footer.component'
import { DetalheGenericoHeaderComponent } from './components/detalhe-generico/detalhe-generico-header/detalhe-generico-header.component'
import { DetalhesCartaoComponent } from './components/detalhes-cartao/detalhes-cartao.component'
import { DocsRelatoriosInvestimentosComponent } from './components/docs-relatorios-investimentos/docs-relatorios-investimentos.component'
import { DownloadPdfToolbarMobileComponent } from './components/download-pdf-toolbar-mobile/download-pdf-toolbar-mobile.component'
import { DownloadPdfToolbarComponent } from './components/download-pdf-toolbar/download-pdf-toolbar.component'
import { FaleComNossoTimeDaycovalDigitalComponent } from './components/fale-com-nosso-time-daycoval-digital/fale-com-nosso-time-daycoval-digital.component'
import { FaleComNossoTimeComponent } from './components/fale-com-nosso-time/fale-com-nosso-time.component'
import { FaqDetalhesComponent } from './components/faq-detalhes/faq-detalhes.component'
import { FaqComponent } from './components/faq/faq.component'
import { FooterContatosComponent } from './components/footer/footer-contatos/footer-contatos.component'
import { FooterComponent } from './components/footer/footer.component'
import { LineChartComponent } from './components/graficos/line-chart/line-chart.component'
import { PieChartComponent } from './components/graficos/pie-chart/pie-chart.component'
import { GridCardProdutoComponent } from './components/grid-card-produto/grid-card-produto.component'
import { GridDeNoticiasComponent } from './components/grid-de-noticias/grid-de-noticias.component'
import { GridDeNoticias2Component } from './components/grid-de-noticias2/grid-de-noticias2.component'
import { GridProdutosDaycovalDigitalComponent } from './components/grid-produtos-daycoval-digital/grid-produtos-daycoval-digital.component'
import { GridProdutosComponent } from './components/grid-produtos/grid-produtos.component'
import { GridVantagensComponent } from './components/grid-vantagens/grid-vantagens.component'
import { AcessoRapidoMenuComponent } from './components/header/acesso-rapido-menu/acesso-rapido-menu.component'
import { BotoesNavegacaoMenuComponent } from './components/header/botoes-navegacao-menu/botoes-navegacao-menu.component'
import { BtnRiComponent } from './components/header/botoes-navegacao-menu/btn-ri/btn-ri.component'
import { HeaderComponent } from './components/header/header.component'
import { IconesNavegacaoMenuComponent } from './components/header/icones-navegacao-menu/icones-navegacao-menu.component'
import { SubmenuComponent } from './components/header/submenu/submenu.component'
import { TermoAceiteLgpdComponent } from './components/header/termo-aceite-lgpd/termo-aceite-lgpd.component'
import { HistoriaComponent } from './components/historia/historia.component'
import { ImagemComSobreposicaoComponent } from './components/imagem-com-sobreposicao/imagem-com-sobreposicao.component'
import { InformacoesJuridicasInvestimentosComponent } from './components/informacoes-juridicas-investimentos/informacoes-juridicas-investimentos.component'
import { LinkPadraoListaComponent } from './components/link-padrao-lista/link-padrao-lista.component'
import { LinkPadraoComponent } from './components/link-padrao/link-padrao.component'
import { ListaDocumentosMercadoCapitaisComponent } from './components/lista-documentos-mercado-capitais/lista-documentos-mercado-capitais.component'
import { ListaDocumentosComponent } from './components/lista-documentos/lista-documentos.component'
import { ListaLinksDocumentosComponent } from './components/lista-links-documentos/lista-links-documentos.component'
import { LoadingComponent } from './components/loading/loading.component'
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component'
import { AcesseSuaContaModalComponent } from './components/modais/acesse-sua-conta-modal/acesse-sua-conta-modal.component'
import { CadastroPositivoQueroCadastrarComponent } from './components/modais/cadastro-positivo-quero-cadastrar/cadastro-positivo-quero-cadastrar.component'
import { CreditoEmContaQueroContratarComponent } from './components/modais/credito-em-conta-quero-contratar/credito-em-conta-quero-contratar.component'
import { DicasSegurancaCancelamentoEmprestimoConsigComponent } from './components/modais/dicas-seguranca-cancelamento-emprestimo-consig/dicas-seguranca-cancelamento-emprestimo-consig.component'
import { DicasSegurancaCartaoClonadoModalComponent } from './components/modais/dicas-seguranca-cartao-clonado-modal/dicas-seguranca-cartao-clonado-modal.component'
import { DicasSegurancaFalsoFuncionarioModalComponent } from './components/modais/dicas-seguranca-falso-funcionario-modal/dicas-seguranca-falso-funcionario-modal.component'
import { DicasSegurancaFalsoInvestimentoModalComponent } from './components/modais/dicas-seguranca-falso-investimento-modal/dicas-seguranca-falso-investimento-modal.component'
import { DicasSegurancaFiqueAtentoGolpesModalComponent } from './components/modais/dicas-seguranca-fique-atento-golpes-modal/dicas-seguranca-fique-atento-golpes-modal.component'
import { DicasSegurancaGolpeWhatsappModalComponent } from './components/modais/dicas-seguranca-golpe-whatsapp-modal/dicas-seguranca-golpe-whatsapp-modal.component'
import { DicasSegurancaPhishingModalComponent } from './components/modais/dicas-seguranca-phishing-modal/dicas-seguranca-phishing-modal.component'
import { DicasSegurancaPixModalComponent } from './components/modais/dicas-seguranca-pix-modal/dicas-seguranca-pix-modal.component'
import { DicasSegurancaRedesSociaisModalComponent } from './components/modais/dicas-seguranca-redes-sociais-modal/dicas-seguranca-redes-sociais-modal.component'
import { DicasSegurancaVeiculosComponent } from './components/modais/dicas-seguranca-veiculos/dicas-seguranca-veiculos.component'
import { LoadingModalComponent } from './components/modais/loading-modal/loading-modal.component'
import { SucessoModalComponent } from './components/modais/mensagens/sucesso-modal/sucesso-modal.component'
import { ModalContatoLeadComponent } from './components/modais/modal-contato-lead/modal-contato-lead.component'
import { ModalFaleComUmGerenteComponent } from './components/modais/modal-fale-com-um-gerente/modal-fale-com-um-gerente.component'
import { PrincipaisAtendimentosModalComponent } from './components/modais/principais-atendimentos-modal/principais-atendimentos-modal.component'
import { RegistrarNovaSolicitacaoModalComponent } from './components/modais/registrar-nova-solicitacao-modal/registrar-nova-solicitacao-modal.component'
import { SemProtocolosAndamentoModalComponent } from './components/modais/sem-protocolos-andamento-modal/sem-protocolos-andamento-modal.component'
import { FormSolicitaCartaoConsignadoComponent } from './components/modais/solicitar-cartao/form-solicita-cartao-consignado/form-solicita-cartao-consignado.component'
import { FormSolicitaCartaoComponent } from './components/modais/solicitar-cartao/form-solicita-cartao/form-solicita-cartao.component'
import { SolicitarCartaoAvisoComponent } from './components/modais/solicitar-cartao/solicitar-cartao-aviso/solicitar-cartao-aviso.component'
import { SolicitarCartaoErroComponent } from './components/modais/solicitar-cartao/solicitar-cartao-erro/solicitar-cartao-erro.component'
import { SolicitarCartaoSucessoComponent } from './components/modais/solicitar-cartao/solicitar-cartao-sucesso/solicitar-cartao-sucesso.component'
import { SolicitarCartaoComponent } from './components/modais/solicitar-cartao/solicitar-cartao.component'
import { TermosLgpdModalComponent } from './components/modais/termos-lgpd-modal/termos-lgpd-modal.component'
import { ValidarTokenSmsModalComponent } from './components/modais/validar-token-sms-modal/validar-token-sms-modal.component'
import { MosaicoFixoComponent } from './components/mosaico-fixo/mosaico-fixo.component'
import { NotificacaoMacicaComponent } from './components/notificacao-macica/notificacao-macica.component'
import { OutrosCanaisAtendimentoComponent } from './components/outros-canais-atendimento/outros-canais-atendimento.component'
import { OutrosContatosAtendimentoComponent } from './components/outros-contatos-atendimento/outros-contatos-atendimento.component'
import { PassoAPassoImagemCruzadoComponent } from './components/passo-a-passo-imagem-cruzado/passo-a-passo-imagem-cruzado.component'
import { PassoAPassoComponent } from './components/passo-a-passo/passo-a-passo.component'
import { NotaPesquisaSatisfacaoBoxComponent } from './components/pesquisa-satisfacao-components/nota-pesquisa-satisfacao-box/nota-pesquisa-satisfacao-box.component'
import { PesquisaSatisfacaoOuvidoriaComponent } from './components/pesquisa-satisfacao-components/pesquisa-satisfacao-ouvidoria/pesquisa-satisfacao-ouvidoria.component'
import { PesquisaSatisfacaoComponent } from './components/pesquisa-satisfacao-components/pesquisa-satisfacao/pesquisa-satisfacao.component'
import { ProcurandoAjudaAtendimentoComponent } from './components/procurando-ajuda-atendimento/procurando-ajuda-atendimento.component'
import { ProdutosComponent } from './components/produtos/produtos.component'
import { ResultadoCarteiraComponent } from './components/resultado-carteira/resultado-carteira.component'
import { SimuladorCreditoComponent } from './components/simulador-credito/simulador-credito.component'
import { SimuladorFGTSComponent } from './components/simulador-fgts/simulador-fgts.component'
import { FormCarteiraSimuladorInvestimentosComponent } from './components/simulador-investimentos-partial/form-carteira-simulador-investimentos/form-carteira-simulador-investimentos.component'
import { SimuladorInvestimentosPartialComponent } from './components/simulador-investimentos-partial/simulador-investimentos-partial.component'
import { SugestaoInvestimentoComponent } from './components/sugestao-investimento/sugestao-investimento.component'
import { TenhaMaisFacilidadePjComponent } from './components/tenha-mais-facilidade-pj/tenha-mais-facilidade-pj.component'
import { TermoAceiteLgpdFormComponent } from './components/termo-aceite-lgpd-form/termo-aceite-lgpd-form.component'
import { TextoJuridicoMastercardComponent } from './components/texto-juridico-mastercard/texto-juridico-mastercard.component'
import { VantagensFgtsComponent } from './components/vantagens-fgts/vantagens-fgts.component'

//Pipes && Directives
import { AutofocusDirective } from './directives/autofocus.directive'
import { InputPercentBrDirective } from './directives/input-percent-br.directive'
import { MatDatepickerMaskDirective } from './directives/mat-datepicker-mask.directive'
import { NumericDirective } from './directives/numeric.directive'
import { CpfCnpjPipe } from './pipes/cpfcnpj.pipe'
import { EncodePipe } from './pipes/encode.pipe'
import { FormatarEnderecoLojaPipe } from './pipes/formatar-endereco-loja.pipe'
import { RemoveTimePipe } from './pipes/remove-time.pipe'
import { RemoveWhitespacePipe } from './pipes/remove-whitespace.pipe'
import { TagPRemovePipe } from './pipes/tag-p-remove.pipe'

//Material Angular Components
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSliderModule } from '@angular/material/slider'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'

//NGX
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { NgxCurrencyModule } from 'ngx-currency'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { NgxSpinnerModule } from 'ngx-spinner'
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

import localePt from '@angular/common/locales/pt'
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha'
import { AccordionComponent } from './components/accordion/accordion.component'
import { AvisoCamposObrigatoriosComponent } from './components/aviso-campos-obrigatorios/aviso-campos-obrigatorios.component'
import { DayReCaptchaV3Component } from './components/day-re-captcha-v3/day-re-captcha-v3.component'
import { DocsAtendimentoComponent } from './components/docs-atendimento/docs-atendimento.component'
import { FaleConoscoComponent } from './components/fale-conosco/fale-conosco.component'
import { BloqueioDesbloqueioModalComponent } from './components/modais/bloqueio-desbloqueio-modal/bloqueio-desbloqueio-modal.component'
import { ParcelamentoModalComponent } from './components/modais/parcelamento-modal/parcelamento-modal.component'
import { FormSolicitaCartaoBeneficioConsignadoComponent } from './components/modais/solicitar-cartao/form-solicita-cartao-beneficio-consignado/form-solicita-cartao-beneficio-consignado.component'
import { AsCaracteriscasComponent } from './components/renda-fixa/as-caracteriscas/as-caracteriscas.component'
import { BotaoNossosProdutosComponent } from './components/renda-fixa/botao-nossos-produtos/botao-nossos-produtos.component'
import { ConhecaNossosProdutosComponent } from './components/renda-fixa/conheca-nossos-produtos/conheca-nossos-produtos.component'
import { OQueSaoTitulosComponent } from './components/renda-fixa/o-que-sao-titulos/o-que-sao-titulos.component'
import { QuaisSaoAsVantagensComponent } from './components/renda-fixa/quais-sao-as-vantagens/quais-sao-as-vantagens.component'
import { SeloAmbimaComponent } from './components/renda-fixa/selo-ambima/selo-ambima.component'
import { ConfirmacaoTokenSimuladorInvestimentosComponent } from './components/simulador-investimentos-partial/confirmacao-token-simulador-investimentos/confirmacao-token-simulador-investimentos.component'
import { RestrictInputDirective } from './directives/restrict-input-directive'
import { RestrictInputNameDirective } from './directives/restrict-input-name-directive'
import { SanitazePipe } from './pipes/sanitaze.pipe'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgApexchartsModule } from "ng-apexcharts"
import { BannerConteudoAlinhadoPrevidenciaComponent } from './components/banner-conteudo-alinhado-previdencia/banner-conteudo-alinhado-previdencia.component'
import { BannerMdPrevidenciaComponent } from './components/banner-md-previdencia/banner-md-previdencia.component'
import { CardsInformativosPrevidenciaComponent } from './components/cards-informativos-previdencia/cards-informativos-previdencia.component'
import { ChartPrevidenciaComponent } from './components/chart-previdencia/chart-previdencia.component'
import { FalarSobrePrevidenciaComponent } from './components/modais/falar-sobre-previdencia/falar-sobre-previdencia.component'
import { ConfirmacaoTokenSimuladorPrevidenciaComponent } from './components/simulador-previdencia-partial/confirmacao-token-simulador-previdencia/confirmacao-token-simulador-previdencia.component'
import { SimuladorPrevidenciaPartialComponent } from './components/simulador-previdencia-partial/simulador-previdencia-partial.component'
import { TermosCondicoesUsoComponent } from './components/termos-condicoes-uso/termos-condicoes-uso.component'
import { SafePipe } from './pipes/safe.pipe'

import { ContatoServicosComponent } from './components/contatos/contato-servicos/contato-servicos.component'
import { DestaqueBlogComponent } from './components/destaque-blog/destaque-blog.component'
import { FaqConteudoComponent } from './components/faq-conteudo/faq-conteudo.component'
import { CetEspelhoComponent } from './components/header/cet-espelho/cet-espelho.component'
import { BloqueioContaComponent } from './components/modais/bloqueio-conta/bloqueio-conta.component'
import { CetEspelhoModalComponent } from './components/modais/cet-espelho-modal/cet-espelho-modal.component'
import { DicasSegurancaGolpeAjuizadoModalComponent } from './components/modais/dicas-seguranca-golpe-ajuizado-modal/dicas-seguranca-golpe-ajuizado-modal.component'
import { BannerConteudoAlinhadoRendaFixaComponent } from './components/renda-fixa/banner-conteudo-alinhado-renda-fixa/banner-conteudo-alinhado-renda-fixa.component'
import { BotaoAcesseEVejaNossosProdutosComponent } from './components/renda-fixa/botao-acesse-e-veja-nossos-produtos/botao-acesse-e-veja-nossos-produtos.component'
import { CardsInformativosProdutosRendaFixaComponent } from './components/renda-fixa/cards-informativos-produtos-renda-fixa/cards-informativos-produtos-renda-fixa.component'
import { CardsInformativosRendaFixaComponent } from './components/renda-fixa/cards-informativos-renda-fixa/cards-informativos-renda-fixa.component'
import { TermoAceiteLgpdAutoAtendimentoFormComponent } from './components/termo-aceite-lgpd-auto-atendimento-form/termo-aceite-lgpd-auto-atendimento-form.component'

registerLocaleData(localePt);
const RECAPTCHA_V3_KEY = '6Lc7SwklAAAAABCh6RKWhPl_TLFm5F7ve_x-hM-9';
const RECAPTCHA_V2_KEY = '6LcqF3IUAAAAABRAukkHHGtIDnGcsLf1nFe6hLnv';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FooterContatosComponent,
    LoadingComponent,
    LinkPadraoComponent,
    TagPRemovePipe,
    RemoveTimePipe,
    RemoveWhitespacePipe,
    FormatarEnderecoLojaPipe,
    SanitazePipe,
    AcessoRapidoMenuComponent,
    PieChartComponent,
    BreadcrumbComponent,
    LineChartComponent,
    BannerLgComponent,
    BannerLgMeioComponent,
    ModalFaleComUmGerenteComponent,
    BannerMdComponent,
    FaqComponent,
    FaqDetalhesComponent,
    BannerCarrosselComponent,
    BoxConteudoLinksComponent,
    MosaicoFixoComponent,
    GridDeNoticiasComponent,
    SucessoModalComponent,
    ArtigosComponent,
    InformacoesJuridicasInvestimentosComponent,
    ListaLinksDocumentosComponent,
    BannerBoxExpansivelComponent,
    GridProdutosComponent,
    GridProdutosDaycovalDigitalComponent,
    NumericDirective,
    ContatoCambioComponent,
    ContatoAbrirContaComponent,
    FaleComNossoTimeComponent,
    FaleComNossoTimeDaycovalDigitalComponent,
    ContatoInvestimentosComponent,
    ContatoTesourariaComponent,
    ValidarTokenSmsModalComponent,
    LoadingModalComponent,
    BtnPedirCartaoComponent,
    RegistrarNovaSolicitacaoModalComponent,
    PrincipaisAtendimentosModalComponent,
    SemProtocolosAndamentoModalComponent,
    SolicitarCartaoComponent,
    MatDatepickerMaskDirective,
    ProcurandoAjudaAtendimentoComponent,
    HistoriaComponent,
    SugestaoInvestimentoComponent,
    ProdutosComponent,
    BlogComponent,
    SimuladorInvestimentosPartialComponent,
    OutrosCanaisAtendimentoComponent,
    BoxAcompanheSeuAtendimentoComponent,
    CanaisAtendimentoComponent,
    ConverseDayaneComponent,
    CardsInformativosComponent,
    BannerInformacoesProdutoComponent,
    BannerInformacoesProdutoDaypayComponent,
    CarrosselConteudosComponent,
    ImagemComSobreposicaoComponent,
    MatSelectSearchComponent,
    AcesseSuaContaModalComponent,
    SolicitarCartaoSucessoComponent,
    FormSolicitaCartaoComponent,
    FormSolicitaCartaoConsignadoComponent,
    FormSolicitaCartaoBeneficioConsignadoComponent,
    SolicitarCartaoErroComponent,
    BannerConteudoCruzadoComponent,
    AcessoRapidoComponent,
    PassoAPassoComponent,
    BoxDocumentoComponent,
    GridDeNoticias2Component,
    BannerConteudoAlinhadoComponent,
    BannerConteudoAlinhadoIconsComponent,
    ListaDocumentosComponent,
    ListaDocumentosMercadoCapitaisComponent,
    DocsRelatoriosInvestimentosComponent,
    BannerLinksAlinhadoComponent,
    ContatoLojaComponent,
    BoxLojaComponent,
    SimuladorCreditoComponent,
    GridCardProdutoComponent,
    BannerVideoComponent,
    DayReCaptchaComponent,
    DayReCaptchaModalComponent,
    DayconnectNaWebComponent,
    PesquisaSatisfacaoComponent,
    GridVantagensComponent,
    PesquisaSatisfacaoOuvidoriaComponent,
    TermoAceiteLgpdComponent,
    BannerFlipbookDaycovalComponent,
    ContatoInvestimentosPjComponent,
    CadastroPositivoQueroCadastrarComponent,
    BannerComBotaoComponent,
    ModalContatoLeadComponent,
    OutrosContatosAtendimentoComponent,
    SolicitarCartaoAvisoComponent,
    BoxConsultaCobradoraComponent,
    DicasSegurancaPixModalComponent,
    DicasSegurancaFalsoFuncionarioModalComponent,
    DicasSegurancaFalsoInvestimentoModalComponent,
    DicasSegurancaCartaoClonadoModalComponent,
    DicasSegurancaGolpeWhatsappModalComponent,
    DicasSegurancaPhishingModalComponent,
    BoxAtendimentoComponent,
    DicasSegurancaRedesSociaisModalComponent,
    DicasSegurancaFiqueAtentoGolpesModalComponent,
    TermoAceiteLgpdFormComponent,
    SubmenuComponent,
    DetalhesCartaoComponent,
    BoxSobreCartaoComponent,
    BoxConhecaCartoesComponent,
    CreditoEmContaQueroContratarComponent,
    BannerAtendimentoComponent,
    TenhaMaisFacilidadePjComponent,
    BoxConhecaVantagensCarrosselComponent,
    TermosLgpdModalComponent,
    FormCarteiraSimuladorInvestimentosComponent,
    AutofocusDirective,
    LinkPadraoListaComponent,
    DicasSegurancaCancelamentoEmprestimoConsigComponent,
    DicasSegurancaVeiculosComponent,
    NotaPesquisaSatisfacaoBoxComponent,
    InputPercentBrDirective,
    BotoesNavegacaoMenuComponent,
    IconesNavegacaoMenuComponent,
    EncodePipe,
    BtnRiComponent,
    BarraSimuladorRendaFixaComponent,
    TextoJuridicoMastercardComponent,
    DetalheGenericoFooterComponent,
    DetalheGenericoHeaderComponent,
    DownloadPdfToolbarComponent,
    DownloadPdfToolbarMobileComponent,
    VantagensFgtsComponent,
    PassoAPassoImagemCruzadoComponent,
    SimuladorFGTSComponent,
    NotificacaoMacicaComponent,
    ResultadoCarteiraComponent,
    BannerVideoSegurancaComponent,
    FaleConoscoComponent,
    AccordionComponent,
    BloqueioDesbloqueioModalComponent,
    ParcelamentoModalComponent,
    RestrictInputDirective,
    RestrictInputNameDirective,
    DayReCaptchaV3Component,
    DocsAtendimentoComponent,
    CpfCnpjPipe,
    DayReCaptchaV3Component,
    AvisoCamposObrigatoriosComponent,
    ConfirmacaoTokenSimuladorInvestimentosComponent,
    SimuladorPrevidenciaPartialComponent,
    TermosCondicoesUsoComponent,
    ConfirmacaoTokenSimuladorPrevidenciaComponent,
    SafePipe,
    FalarSobrePrevidenciaComponent,
    BannerMdPrevidenciaComponent,
    CardsInformativosPrevidenciaComponent,
    BannerConteudoAlinhadoPrevidenciaComponent, ChartPrevidenciaComponent,
    ConhecaNossosProdutosComponent,
    QuaisSaoAsVantagensComponent,
    OQueSaoTitulosComponent,
    AsCaracteriscasComponent,
    SeloAmbimaComponent,
    BotaoNossosProdutosComponent,
    CardsInformativosRendaFixaComponent,
    CardsInformativosProdutosRendaFixaComponent,
    BannerConteudoAlinhadoRendaFixaComponent,
    BotaoAcesseEVejaNossosProdutosComponent,
    TermoAceiteLgpdAutoAtendimentoFormComponent,
    ContatoServicosComponent,
    CetEspelhoComponent,
    CetEspelhoModalComponent,
    DestaqueBlogComponent,
    DicasSegurancaGolpeAjuizadoModalComponent,
    BloqueioContaComponent,
    FaqConteudoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    NgxChartsModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRippleModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTableModule,
    MatSliderModule,
    MatCheckboxModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    MatIconModule,
    NgxMaskModule.forRoot(options),
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    MatDatepickerModule,
    MatNativeDateModule,
    NgApexchartsModule,
    NgbModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    LinkPadraoComponent,
    PieChartComponent,
    SimuladorCreditoComponent,
    BreadcrumbComponent,
    LineChartComponent,
    BannerLgComponent,
    BannerLgMeioComponent,
    MatSelectSearchComponent,
    DownloadPdfToolbarComponent,
    DownloadPdfToolbarMobileComponent,
    BlogComponent,
    BoxLojaComponent,
    BannerVideoComponent,
    BtnPedirCartaoComponent,
    InformacoesJuridicasInvestimentosComponent,
    SucessoModalComponent,
    TenhaMaisFacilidadePjComponent,
    ProcurandoAjudaAtendimentoComponent,
    ModalFaleComUmGerenteComponent,
    EncodePipe,
    CadastroPositivoQueroCadastrarComponent,
    ContatoLojaComponent,
    CreditoEmContaQueroContratarComponent,
    BoxConhecaCartoesComponent,
    DetalheGenericoFooterComponent,
    HistoriaComponent,
    BoxAtendimentoComponent,
    BannerLinksAlinhadoComponent,
    BannerMdComponent,
    GridVantagensComponent,
    ContatoInvestimentosPjComponent,
    DayconnectNaWebComponent,
    BoxConsultaCobradoraComponent,
    DetalhesCartaoComponent,
    BoxDocumentoComponent,
    OutrosCanaisAtendimentoComponent,
    BoxAcompanheSeuAtendimentoComponent,
    CanaisAtendimentoComponent,
    ListaDocumentosComponent,
    ListaDocumentosMercadoCapitaisComponent,
    DocsRelatoriosInvestimentosComponent,
    ConverseDayaneComponent,
    BannerConteudoAlinhadoComponent,
    BannerConteudoAlinhadoIconsComponent,
    FaqComponent,
    FaqDetalhesComponent,
    BannerConteudoCruzadoComponent,
    BannerCarrosselComponent,
    BoxConteudoLinksComponent,
    CarrosselConteudosComponent,
    MosaicoFixoComponent,
    SugestaoInvestimentoComponent,
    ProdutosComponent,
    GridDeNoticiasComponent,
    ListaLinksDocumentosComponent,
    BannerBoxExpansivelComponent,
    GridProdutosComponent,
    GridProdutosDaycovalDigitalComponent,
    NumericDirective,
    RestrictInputDirective,
    RestrictInputNameDirective,
    MatDatepickerMaskDirective,
    SimuladorInvestimentosPartialComponent,
    ContatoCambioComponent,
    ContatoAbrirContaComponent,
    ContatoServicosComponent,
    ContatoInvestimentosComponent,
    ContatoTesourariaComponent,
    BannerInformacoesProdutoComponent,
    BannerInformacoesProdutoDaypayComponent,
    FaleComNossoTimeComponent,
    FaleComNossoTimeDaycovalDigitalComponent,
    BoxSobreCartaoComponent,
    ValidarTokenSmsModalComponent,
    LoadingModalComponent,
    TagPRemovePipe,
    RemoveTimePipe,
    RemoveWhitespacePipe,
    FormatarEnderecoLojaPipe,
    SanitazePipe,
    RegistrarNovaSolicitacaoModalComponent,
    PrincipaisAtendimentosModalComponent,
    SemProtocolosAndamentoModalComponent,
    PesquisaSatisfacaoComponent,
    SolicitarCartaoComponent,
    CardsInformativosComponent,
    ImagemComSobreposicaoComponent,
    AcessoRapidoComponent,
    DetalheGenericoHeaderComponent,
    PassoAPassoComponent,
    GridDeNoticias2Component,
    GridCardProdutoComponent,
    DayReCaptchaComponent,
    DayReCaptchaModalComponent,
    BannerFlipbookDaycovalComponent,
    BannerComBotaoComponent,
    OutrosContatosAtendimentoComponent,
    DicasSegurancaPixModalComponent,
    DicasSegurancaFalsoFuncionarioModalComponent,
    DicasSegurancaFalsoInvestimentoModalComponent,
    DicasSegurancaCartaoClonadoModalComponent,
    DicasSegurancaCancelamentoEmprestimoConsigComponent,
    TermoAceiteLgpdFormComponent,
    BannerAtendimentoComponent,
    BoxConhecaVantagensCarrosselComponent,
    LinkPadraoListaComponent,
    BarraSimuladorRendaFixaComponent,
    TextoJuridicoMastercardComponent,
    VantagensFgtsComponent,
    PassoAPassoImagemCruzadoComponent,
    SimuladorFGTSComponent,
    NotificacaoMacicaComponent,
    ResultadoCarteiraComponent,
    BannerVideoSegurancaComponent,
    FaleConoscoComponent,
    AccordionComponent,
    DocsAtendimentoComponent,
    CpfCnpjPipe,
    DayReCaptchaV3Component,
    AvisoCamposObrigatoriosComponent,
    SimuladorPrevidenciaPartialComponent,
    TermosCondicoesUsoComponent,
    BannerMdPrevidenciaComponent,
    CardsInformativosPrevidenciaComponent,
    BannerConteudoAlinhadoPrevidenciaComponent,
    ConhecaNossosProdutosComponent,
    QuaisSaoAsVantagensComponent,
    OQueSaoTitulosComponent,
    AsCaracteriscasComponent,
    SeloAmbimaComponent,
    BotaoNossosProdutosComponent,
    CardsInformativosRendaFixaComponent,
    CardsInformativosProdutosRendaFixaComponent,
    BannerConteudoAlinhadoRendaFixaComponent,
    BotaoAcesseEVejaNossosProdutosComponent,
    TermoAceiteLgpdAutoAtendimentoFormComponent,
    DestaqueBlogComponent,
    CetEspelhoComponent,
    FaqConteudoComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    MatDatepickerModule,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_V3_KEY },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: RECAPTCHA_V2_KEY
      } as RecaptchaSettings
    }
  ]
})
export class SharedModule { }

