import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() exibirLoading: boolean = true
  @Input() diameter: string = "100"

  constructor() { }

  ngOnInit(): void {

  }

}
