import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-bloqueio-conta',
  templateUrl: './bloqueio-conta.component.html',
  styleUrls: ['./bloqueio-conta.component.scss']
})
export class BloqueioContaComponent implements OnInit {

  constructor(   private modal: MatDialog,
    private _blurService: BlurService,
    private _loadingService: LoadingService) { }

    ngOnInit(){
      this._blurService.setBlurBackground(true)
      this._loadingService.setLoading(false)
    }

    ngOnDestroy(): void{
      this._loadingService.setLoading(false)
      this._blurService.setBlurBackground(false)
      this.modal.closeAll()
    }

    fechar() {
      this._loadingService.setLoading(false)
      this.modal.closeAll()
    }

}
