import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  constructor(
    private gmtService: GoogleTagManagerService,
    private router: Router
  ) {}

  tagManegerClienteSimNao(eCliente: boolean) {
    const gtmTag = {
      event: 'simulador_consignado',
      action: 'Cliente',
      label: eCliente ? 'Sim' : 'Não', // Variações: 'Sim' e 'Não', capturado dinamicamente pelo input do usuário
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManegerVeiculo(veiculo: string) {
    const gtmTag = {
      event: 'simulador_consignado',
      action: 'Convênio',
      label: veiculo,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManegerSubmit(acao: string, label: string) {
    const gtmTag = {
      event: 'simulador_submit',
      action: acao,
      label: label,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManegerOferta(acao: string, label: string) {
    const gtmTag = {
      event: 'aproveitar_oferta',
      action: acao, // 'Por valor', //Variações de acordo com opções disponíveis
      label: label, // '12000|84 Prestações' //Variações de acordo com opções disponíveis
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerNovaSimulacao(acao: string, label: string) {
    const gtmTag = {
      event: 'nova_simulacao',
      action: acao, //por valor', //Variações de acordo com opções disponíveis
      label: label, //'12000|84 Prestações' //Variações de acordo com opções disponíveis
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManegerSimulacaoDados(acao: string, label: string, _user_id: string) {
    const gtmTag = {
      event: 'simulador_dados',
      action: acao, //'Por valor', //Variações de acordo com opções disponíveis
      label: label, //'12000|84 Prestações' //Variações de acordo com opções disponíveis
      user_id: _user_id,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerPageView(userID: string) {
    const gtmTag = {
      event: 'virtual_page_view',
      action: undefined, //'Por valor', //Variações de acordo com opções disponíveis
      label: undefined,
      user_id: userID,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerPageNotFound(acao: string, label: string) {
    const gtmTag = {
      event: 'page_not_found_institucional',
      action: acao,
      label: label, //Rota que está tentando acessar
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerPasso1Investimentos(acao: number, label: string) {
    const gtmTag = {
      event: 'investe_simulador',
      action: acao, // valor investimento
      label: label, //tempo de resgate
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerPasso2Investimentos(acao: string, label: string) {
    const gtmTag = {
      event: 'investe_simulador',
      action: acao, // renda mensal
      label: label, //tempo de resgate
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerPasso3Investimentos(acao: string, label: string) {
    const gtmTag = {
      event: 'investe_simulador',
      action: acao, // qual decisão o user tomaria
      label: label, // fonte de recursos
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerCarteiraInvestimentos(user_id: string, label: string) {
    const gtmTag = {
      event: 'investe_simulador',
      action: undefined,
      label: label, // e-mail do usuário
      user_id: undefined, // id_user
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerVisualizarInvestimentos() {
    const gtmTag = {
      event: 'investe_simulador',
      action: "apenas contagem",
      label: undefined,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerNovaSimulacaoInvestimentos() {
    const gtmTag = {
      event: 'investe_simulador',
      action: "nova_simulacao",
      label: undefined,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerMinhaContaInvestimentos() {
    const gtmTag = {
      event: 'investe_simulador',
      action: "acessar_conta",
      label: undefined,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }

  tagManagerAbrirContaInvestimentos() {
    const gtmTag = {
      event: 'investe_simulador',
      action: "abrir_conta",
      label: undefined,
      user_id: undefined,
    };

    this.gmtService.pushTag(gtmTag);
  }
}
