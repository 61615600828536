import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotaPesqSatisfacao } from 'src/app/core/models/nota-pesq-satisfacao.model';

@Component({
  selector: 'app-nota-pesquisa-satisfacao-box',
  templateUrl: './nota-pesquisa-satisfacao-box.component.html',
  styleUrls: ['./nota-pesquisa-satisfacao-box.component.scss']
})
export class NotaPesquisaSatisfacaoBoxComponent implements OnInit {
  @Input() nota: NotaPesqSatisfacao
  @Output() selecionarNota = new EventEmitter<NotaPesqSatisfacao>()
  
  constructor() { }

  ngOnInit(): void {
  }

}
