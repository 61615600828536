<section class="dayane-footer">
    <div class="container">
        <div class="row">
            <div class="logo-d-mobile col-1 col-lg-3">
                <img [src]="urlLogoFooter" alt="Banco Daycoval" title="Banco Daycoval" />
            </div>
            <div class="col-11 col-lg-9" id="col-links">
                <div class="row">
                    <div class="col-12">
                        <div class="links">
                            <ul>
                                <li><a routerLink="/home" target="_blank">Institucional</a></li>
                                <li>
                                    <a routerLink="/institucional/politica-de-privacidade"
                                        target="_blank">Política de Privacidade
                                        e Termos de Uso</a>
                                </li>
                                <li>
                                    <a routerLink="/atendimento"
                                        target="_blank">
                                        Fale Conosco
                                    </a>
                                </li>
                                <li><a routerLink="/ouvidoria" target="_blank">
                                        Ouvidoria </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row copyright">
                    <div class="col-12">
                        <p>Banco Daycoval © {{anoAtual}}. Todos os direitos reservados.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>