<section class="botoes-navegacao-menu">
  <div class="row">
    <div class="col col-ri p-0">
      <app-btn-ri></app-btn-ri>
    </div>

    <div class="col col-btn p-0">
      <button type="button" class="btn" id="consignado-novo" (click)="abrirConsignado()">
        <span>CONSIGNADO</span>
      </button>
    </div>

    <div class="col col-btn p-0">
      <button type="button" class="btn" id="abra-sua-conta-novo" (click)="abrirModalAbraSuaConta()">
        ABRA SUA CONTA
      </button>
    </div>

    <div class="col col-btn p-0">
      <button id="acesse-sua-conta-novo" class="btn" (click)="abrirConta()">
        <span class="user-icon mr-1"></span>
        ACESSAR CONTA
      </button>
    </div>
  </div>
</section>
