import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectSitesExternosGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(route)
    console.log(state)
    console.log(window.location.pathname)
    switch (window.location.pathname) {
      case '/asset':
        window.location.pathname = "/asset/"
        break;
      case '/oghy11':
        window.location.pathname = "/oghy11/"
        break;
      case '/OGHY11':
        window.location.pathname = "/oghy11/"
        break;
      default:
        window.location.pathname = "/"
        break;
    }

    return true;
  }

}
