<section class="nota-pesquisa-satisfacao-box d-flex justify-content-center">
    <div class="box" (click)="selecionarNota.emit(nota)" [ngClass]="(nota.Ativo) ? 'active' : ''" matRipple>
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <i class="material-icons no-selection"
                    [id]="'nota'+nota.Nota">{{nota.Icone}}</i>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <p>{{nota.Nota}}</p>
            </div>
        </div>
    </div>
</section>