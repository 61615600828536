import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UrlCanonicaService {
  constructor(private meta: Meta, private title: Title) { }

  setCanonicalUrl(canonicalUrl?: string, canonicalTitle?: string, description?: string): void {
    this.title.setTitle('Portal Daycoval')

    if (canonicalUrl) {
      document.querySelectorAll('[rel="canonical"]')[0].setAttribute('href', canonicalUrl)
    }

    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    } else {
      this.meta.updateTag({ name: 'description', content: 'Daycoval, um Banco com as melhores opções de crédito, investimentos e serviços de câmbio para você e sua empresa.' });
    }

    if (canonicalTitle) {
      this.title.setTitle(`${canonicalTitle} | Portal Daycoval`);
    } else{
      this.title.setTitle('Portal Daycoval');
    }
  }

  resetMetaTags(): void {
    this.meta.updateTag({ name: 'description', content: 'Daycoval, um Banco com as melhores opções de crédito, investimentos e serviços de câmbio para você e sua empresa.' });
    this.title.setTitle('Portal Daycoval');
  }
}
