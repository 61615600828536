import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DayaneAssuntoRedirectHelper } from 'src/app/core/helpers/dayane-assunto-redirect.helper';
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-sucesso-modal',
  templateUrl: './sucesso-modal.component.html'
})
export class SucessoModalComponent implements OnDestroy, OnInit {
  titulo: string = 'Solicitação realizada com sucesso'
  msg: string
  codSolicitacao: number
  dayaneRedirect: string = DayaneAssuntoRedirectHelper.ObterAtendimento()

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private modal: MatDialog,
    private _blurService: BlurService,
    private _loadingService: LoadingService
  ) {
    if (data) {
      this.msg = data.msg
      this.titulo = data.titulo
      this.codSolicitacao = data.codSolicitacao ?? undefined
    }
  }

  ngOnInit(): void {
    this._blurService.setBlurBackground(true)
  }

  ngOnDestroy(): void {
    this._loadingService.setLoading(false)
    this._blurService.setBlurBackground(false)
    this.modal.closeAll()
  }

  fechar() {
    this._loadingService.setLoading(false)
    this.modal.closeAll()
  }
}
