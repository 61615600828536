import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';

import './prototypes';

//LOCALES
import { registerLocaleData } from '@angular/common';
import localePtExtra from '@angular/common/locales/extra/pt-PT';
import localePt from '@angular/common/locales/pt-PT';
registerLocaleData(localePt, 'pt-PT', localePtExtra);
const PTBR = 'pt-BR';

//NGX

import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module,
} from 'ng-recaptcha';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

//MODULOS INTERNOS
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiHttp } from './core/interceptors/api-http.interceptor';
import { HeaderInterceptor } from './core/interceptors/header-interceptor';
import { LowerCaseUrlSerializer } from './core/providers/LowerCaseUrlSerializer';
import { LandingPageModule } from './modules/landing-page/landing-page.module';
import { PagenotFoundComponent } from './modules/pagenot-found/pagenot-found.component';
import { SharedModule } from './shared/shared.module';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
const RECAPTCHA_V3_KEY = '6Lc7SwklAAAAABCh6RKWhPl_TLFm5F7ve_x-hM-9';
const RECAPTCHA_V2_KEY = '6LcqF3IUAAAAABRAukkHHGtIDnGcsLf1nFe6hLnv';

@NgModule({
  declarations: [AppComponent, PagenotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    LandingPageModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxSpinnerModule,
    MatDialogModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    NgxMaskModule.forRoot(options),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttp, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: PTBR },
    { provide: RECAPTCHA_LANGUAGE, useValue: PTBR },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: 'googleTagManagerId', useValue: 'GTM-MTZ9JC5' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_V3_KEY },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: RECAPTCHA_V2_KEY,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
