import { Component, OnInit } from '@angular/core';
import { ContatosTelefonesEnum } from 'src/app/core/helpers/enums/contatos-telefones.enum';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { urlFiles } from 'src/environments/app.environment';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { DayaneHelper } from 'src/app/core/helpers/dayane.helper';

@Component({
  selector: 'app-dayane-canais-atendimento',
  templateUrl: './dayane-canais-atendimento.component.html',
  styleUrls: ['./dayane-canais-atendimento.component.scss']
})
export class DayaneCanaisAtendimentoComponent implements OnInit {
  imgQrCode: string = `${urlFiles}/cdn/dayane/qrcode.png`
  imgSelo: string = `${urlFiles}/cdn/dayane/selo.png`
  redirectWhatsapp = `${LinksUteisEnum.REDIRECTWHATSAPPDAYANE}&text=Oi`
  WHATSAPP = ContatosTelefonesEnum.DAYANEWHATSAPP
  faWhatsapp = faWhatsapp
  faComments = faComments

  constructor() { }

  ngOnInit(): void {
  }

  abrirDayane(){
    DayaneHelper.abrir()
  }
}
