import { trigger, state, style, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';

@Component({
  selector: 'app-btn-ri',
  templateUrl: './btn-ri.component.html',
  styleUrls: ['./btn-ri.component.scss'],
  animations: [
    trigger('toggle', [
      state('true', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition(':enter', animate('200ms ease-in-out')),
      transition(':leave', animate('200ms ease-in-out')),
    ]),
  ],
})
export class BtnRiComponent implements OnInit {
  titulo: string = 'Relações com Investidores';
  exibirLista: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void { }

  abrirRIEN() {
    window.open(LinksUteisEnum.RIEN, '_blank');
    this.exibirLista = false;
  }

  abrirRI() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(LinksUteisEnum.RI, '_blank');
    }
    this.exibirLista = false
  }
}
