import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Link } from 'src/app/core/models/link.model';
import { environment } from 'src/environments/environment';
import { BloqueioContaComponent } from '../../modais/bloqueio-conta/bloqueio-conta.component';

@Component({
  selector: 'app-acesso-rapido-menu',
  templateUrl: './acesso-rapido-menu.component.html',
  styleUrls: ['./acesso-rapido-menu.component.scss']
})
export class AcessoRapidoMenuComponent implements OnInit {
  @Input() bgColorDescricao: string = '#00194d'
  @Input() bgColorLinks: string = '#001c55'
  @Input() itensAcessoRapido: {img:string, link:Link}[] = []


  //-----------------------------------------------------

  @Input() indexMenuAtivo: number;
  acessoRapido: {indexMenu: number, menu?:{ img: string; link: Link}[]}[] = [];
  acessoRapidoAtivo: { img: string; link: Link}[] = []
  constructor(private modal: MatDialog) { }

  ngOnInit(): void {
    this.getAcessoRapido();
    if(this.indexMenuAtivo != undefined){
      this.acessoRapidoAtivo = this.acessoRapido.find(x => x.indexMenu == this.indexMenuAtivo)?.menu
    }
  }

  abrirModalBloqueioConta() {
    let modalConfig = new MatDialogConfig();
    modalConfig.data = { bloqueioContaDefault: '' };
    this.modal.open(BloqueioContaComponent, modalConfig);
  }

  getAcessoRapido() {
    let path = 'assets/img/icons/menu/acesso-rapido/'
    this.acessoRapido = [
      {
        indexMenu: 0,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 1,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 2,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 3,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 4,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 5,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      },
      {
        indexMenu: 6,
        menu: [
          {
            img: path + 'talk.svg',
            link: new Link('Central de Atendimento', '/atendimento')
          },
          {
            img: path + 'invoice.svg',
            link: new Link('Fatura do Cartão Consignado', environment.redirectDayconnectCartoes, true)
          },
          {
            img: path + 'initiate-money-transfer.svg',
            link: new Link('Crédito Consignado', '/credito-para-voce/credito-consignado')
          },
          {
            img: path + 'bloqueado.svg',
            link: new Link('Bloqueio de Conta', 'modal', false, true)
          },
          {
            img: path + 'card-payment.svg',
            link: new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito')
          },
          {
            img: path + 'increase.svg',
            link: new Link('Daycoval Investe', '/daycoval-investe')
          }
        ]
      }
    ]
  }

}
