<section class="link-padrao" [style.margin-bottom]="marginBottom">
  <div class="d-flex link" [ngClass]="classAlign">
    <i class="material-icons mt-1 mt-md-0" [style.color]="corSeta" [style.font-size]="fontsizeicon">arrow_forward</i>

    <!-- interno -->
    <a *ngIf="!ehLinkExterno && !anchorLink && !ehModal && !linkTexto" [routerLink]="link" [style.color]="corTexto" [style.font-size]="fontsize"
      [style.font-family]="fontFamily" (click)="onClick.emit($event)">
      {{texto}}
    </a>

    <!-- externo -->
    <a *ngIf="ehLinkExterno && !anchorLink && !ehModal && !linkTexto" [href]="link" target="_blank" [style.color]="corTexto"
      [style.font-size]="fontsize" [style.font-family]="fontFamily" (click)="onClick.emit($event)">
      {{texto}}
    </a>

    <!-- link ancora -->
    <a *ngIf="anchorLink && !ehModal && !linkTexto" [style.color]="corTexto" [style.font-size]="fontsize" [style.font-family]="fontFamily" (click)="scrollTo(this.link)" (click)="onClick.emit($event)">
      {{texto}}
    </a>

    <!-- modal -->
    <a *ngIf="ehModal && !linkTexto" [style.color]="corTexto" [style.font-size]="fontsize" [style.font-family]="fontFamily" (click)="abrirModal()" (click)="onClick.emit($event)">
      {{texto}}
    </a>

    <!-- link texto -->
    <span *ngIf="linkTexto" [style.color]="corTexto" [style.font-size]="fontsize" [style.font-family]="fontFamily" (click)="onClick.emit($event)">
      {{texto}}
    </span>
  </div>
</section>
