import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/shared.module';
import { AtendimentoDayaneComponent } from './pages/atendimento-dayane/atendimento-dayane.component';
import { DayaneHomeComponent } from './pages/dayane-home/dayane-home.component';
import { DayaneHeaderComponent } from './pages/dayane-home/dayane-header/dayane-header.component';
import { DayaneBodyComponent } from './pages/dayane-home/dayane-body/dayane-body.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { DayaneCanaisAtendimentoComponent } from './pages/dayane-home/dayane-canais-atendimento/dayane-canais-atendimento.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DayaneFooterComponent } from './pages/dayane-home/dayane-footer/dayane-footer.component';
import { NpsComponent } from './pages/nps/nps.component';
import { PesquisaNpsComponent } from './pages/pesquisa-nps/pesquisa-nps.component';
import { CampanhaDaycovalComponent } from './pages/campanha-daycoval/campanha-daycoval.component';
import { ManutencaoComponent } from './pages/manutencao/manutencao.component';

@NgModule({
  declarations: [
    RedirectComponent,
    AtendimentoDayaneComponent,
    DayaneHomeComponent,
    DayaneHeaderComponent,
    DayaneBodyComponent,
    DayaneCanaisAtendimentoComponent,
    DayaneFooterComponent,
    NpsComponent,
    PesquisaNpsComponent,
    CampanhaDaycovalComponent,
    ManutencaoComponent
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    NgxSpinnerModule,
    SharedModule,
    NgxTypedJsModule,
    FontAwesomeModule
  ]
})
export class LandingPageModule { }
