<section class="modal-generico">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <h2 class="title-modal">Bloqueio de Conta</h2>
    </div>
    <div class="container d-flex justify-content-center align-items-center">
      <div class="box-info" id="descricao">
        <h2 class="mb-0">0300 777 2020 ou 11 3138-0531</h2>
        <p class="disponibilidade">Disponível 24 horas por dia, 7 dias por semana</p>
      </div>
    </div>
  </div>

  <i class="material-icons absolute pointer" id="close" (click)="fechar()" (keypress)="fechar()" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
</section>
