export class Link {
  constructor(
    descricao: string,
    link: string,
    ehLinkExterno?: boolean,
    ehModal?: boolean,
    ehBold?:boolean,
    subTitulo?: string,
  ) {
    this.Descricao = descricao
    this.Link = link
    this.EhLinkExterno = ehLinkExterno == null ? false : ehLinkExterno
    this.ehModal = ehModal
    this.ehBold = ehBold
    this.SubTitulo = subTitulo
    }
  Descricao: string
  Link: string
  EhLinkExterno: boolean
  ehModal?: boolean
  ehBold?:boolean
  SubTitulo?: string
}
