import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dayane-home',
  templateUrl: './dayane-home.component.html',
  styleUrls: ['./dayane-home.component.scss']
})
export class DayaneHomeComponent implements OnInit {

  constructor(
    private viewportScroller: ViewportScroller
  ) { 
    
  }

  ngOnInit(): void {        
    
  }

  irParaContato() {    
    this.viewportScroller.scrollToAnchor('contato-anchor')
  }
}
