<section class="header">
  <div class="menu d-none d-lg-block">
    <nav class="navbar navbar-expand-lg navbar-dark area-menu-principal bg-white" aria-label="Menu principal do site">
      <div class="container px-0 position-static">
        <div class="collapse navbar-collapse flex-wrap mw-100" id="day-navbar">
          <div class="row w-100 align-items-center pt-3 mb-4">
            <div class="col-12 d-flex space">
              <a class="navbar-brand brand-logo d-block p-0" routerLink="/home">
                <img
                  class="align-top img-fluid pl-0"
                  [src]="'assets/img/logo/logo-daycoval-dark.png'"
                  alt="Logo Banco Daycoval"
                />
              </a>
              <div id="link-home" class="center icone-link-rapido">
                <span matTooltip="Ir para home">
                  <a class="d-flex align-items-center" routerLink="/home">
                    <i class="material-icons mr-1">home</i>
                    <span class="non-link-text align-middle"
                      >Site Banco Daycoval</span
                    >
                  </a>
                </span>
              </div>
            </div>
            <div class="col-9">
              <div class="header-links d-flex justify-content-end w-100">

              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!--MOBILE-->

  <div class="menu menu-mobile d-block d-lg-none">
    <nav
      class="navbar navbar-expand-lg navbar-dark area-menu-principal bg-white" aria-label="Menu principal do site"
    >
      <div class="container mw-100 px-0 relative">
        <div class="row fst w-100 mx-0">
          <div class="col-6 col-sm-8 col-md-4 px-0 mx-0 center" id="logo">
            <a
              class="navbar-brand brand-logo d-block p-0 m-0"
              routerLink="/home"
            >
              <img
                class="align-top img-fluid ml-1 pl-0"
                [src]="'assets/img/logo/logo-daycoval-dark.png'"
                alt="Logo Banco Daycoval"
              />
            </a>
          </div>
          <div class="col-4 col-sm-2 col-md-4 px-0 mx-0">
            <div class="header-links d-flex justify-content-end w-100">
              <div id="link-home" class="center icone-link-rapido">
                <span matTooltip="Ir para home">
                  <a class="d-flex align-items-center" routerLink="/home">
                    <i class="material-icons mr-1">home</i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</section>
