import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { urlApiInstitucional } from 'src/environments/app.api';
import { InserirRespostaNpsInstitucionalSignature } from '../models/nps/inserir-resposta-nps-institucional-signature.model';
import { PesquisaNpsResult } from '../models/result/pesquisa-nps-result.model';

@Injectable({
  providedIn: 'root'
})
export class PesquisaNpsService {

  constructor(
    private http: HttpClient,

  ) { }

  RegistrarPesquisaNPS(resposta: InserirRespostaNpsInstitucionalSignature): Observable<PesquisaNpsResult> {
    return this.http.post<PesquisaNpsResult>(
      `${urlApiInstitucional}/Nps/InserirRespostaInstitucional`,
      resposta).pipe(

        map((res: PesquisaNpsResult) => {

          return res || new PesquisaNpsResult()
        }));
  }
}
