import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-cet-espelho-modal',
  templateUrl: './cet-espelho-modal.component.html',
  styleUrls: ['./cet-espelho-modal.component.scss']
})
export class CetEspelhoModalComponent implements OnInit {
  titulo: string = ''
  signatureUrl: string;
  signatureNomeArquivo: string;
  signatureTipoRelatorio: number;

  constructor(
    public dialog: MatDialogRef<CetEspelhoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { signatureUrl: string, signatureNomeArquivo: string, signatureTipoRelatorio: number },
    private _modalService: ModalService,
    private _blurService: BlurService,
    private _loadingService: LoadingService) {
    this.signatureUrl = data.signatureUrl;
    this.signatureNomeArquivo = data.signatureNomeArquivo;
    this.signatureTipoRelatorio = data.signatureTipoRelatorio;
  }

  ngOnInit(): void {
    if (this.signatureTipoRelatorio === 0) {
      this.titulo = 'CET - CUSTO EFETIVO TOTAL';
    } else {
      this.titulo = 'ESPELHO';
    }
  }

  ngOnDestroy(): void {
    this._blurService.setBlurBackground(false)
    this._loadingService.setLoading(false)
    this.dialog.close()
  }

  baixarDocumento(): void {
    this.documentoPDF(this.signatureUrl, this.signatureNomeArquivo);
  }

  documentoPDF(urlDoPDF: string, nomeArquivo: string): void {

    fetch(urlDoPDF)
      .then(response => response.blob())
      .then(blob => {

        const urlBlob = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = urlBlob;
        link.download = nomeArquivo;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Erro ao baixar o PDF:', error);
      });
  }

  fechar() {
    this._loadingService.setLoading(false)
    this._modalService.fecharTudo()
  }
}