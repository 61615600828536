<section class="acesso-rapido-menu d-none d-lg-flex justify-content-start justify-content-lg-between"
*ngIf="acessoRapidoAtivo">
  <div class="container">
    <div class="row w-100">
      <div class="col-12 col-lg-3 d-block d-lg-block border-top-blue" id="menu-descricao">
        <div class="row ml-0 relative d-flex">
          <span class="no-space acesso-rapido-titulo">Acesso rápido <span class="ponto-grande" id="branco"></span></span>
        </div>
      </div>
      <div class="col-12 col-lg-9" id="links-acesso-rapido">
        <div class="row border-top">
          <div class="col-6 col-lg-4 center item-acesso-rapido pb-2 px-2" *ngFor="let item of acessoRapidoAtivo">
            <i class="icons">
              <img src="{{item.img}}" alt="">
            </i>
            <a *ngIf="item.link.EhLinkExterno" href="{{item.link.Link}}" target="_blank">{{item.link.Descricao}}</a>
            <a *ngIf="!item.link.EhLinkExterno && !item.link.ehModal" href="{{item.link.Link}}">{{item.link.Descricao}}</a>
            <a *ngIf="!item.link.EhLinkExterno && item.link.ehModal" (click)="abrirModalBloqueioConta()">{{item.link.Descricao}}</a>          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="acesso-rapido-menu d-flex d-lg-none justify-content-start justify-content-lg-between"
*ngIf="acessoRapidoAtivo">
  <div class="container mw-100">
    <div class="row">
      <div class="col-12 col-lg-3 d-block d-lg-block border-top-blue" id="menu-descricao">
        <div class="row relative d-flex">
          <span class="no-space">Acesso rápido <span class="ponto-grande" id="branco"></span></span>
        </div>
      </div>
      <div class="col-12 col-lg-9 px-3" id="links-acesso-rapido">
        <div class="row pt-0">
          <div class="col-12 col-sm-6 col-lg-4 center item-acesso-rapido py-1" *ngFor="let item of acessoRapidoAtivo">
            <i class="icons">
              <img src="{{item.img}}" alt="">
            </i>
            <a *ngIf="item.link.EhLinkExterno" href="{{item.link.Link}}" target="_blank">{{item.link.Descricao}}</a>
            <a *ngIf="!item.link.EhLinkExterno" href="{{item.link.Link}}">{{item.link.Descricao}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




