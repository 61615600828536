const url = 'https://www.daycoval.com.br/AtendimentoDayane?Assunto'

export class DayaneAssuntoRedirectHelper{
  public static ObterSaldoDevedor(): string{
    return `${url}=SaldoDevedor`
  }

  public static ObterRefinanciarContratar(): string{
    return `${url}=RefinanciarContratar`
  }

  public static ObterDuvida(): string{
    return `${url}=Duvida`
  }

  public static ObterAtendimento(): string{
    return `${url}=Atendimento`
  }

  public static ObterInvestimentos(): string{
    return `${url}=Investimentos`
  }
}
