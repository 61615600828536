import { FiltroTipoLoja } from "../models/lojas/filtro-tipo-loja.model"
import { Coordenadas } from "../models/maps/coordenadas.model"
export const coordenadasAgenciaMatriz: Coordenadas = { lat: -23.560348626396184, lng: -46.65787435792155 }
export const urlAgencias: string = '/institucional/agencias-e-correspondentes'

export class LojasHelper {
  public static obterCategoriaLoja(tipoLoja: string): string {
    let categoria: string

    switch (tipoLoja) {
      case RotaLojasEnum.AGENCIAS:
        categoria = 'AGENCIASDAYCOVAL'
        break
      case RotaLojasEnum.CAMBIO:
        categoria = 'LOJASCAMBIO'
        break
      case RotaLojasEnum.CONSIGNADO:
        categoria = 'CORRESPONDENTESCONSIGNADO'
        break
      case RotaLojasEnum.VEICULOS:
        categoria = 'CORRESPONDENTESVEICULOS'
        break
      case RotaLojasEnum.CGI:
        categoria = 'CORRESPONDENTESIMOBILIARIOS'
        break
      case RotaLojasEnum.DAYPAG:
        categoria = 'POSTOSDAYPAG'
        break
      case RotaLojasEnum.CAMBIOSIMPLES:
        categoria = 'CAMBIOSIMPLES'
        break
      default:
        categoria = null
    }

    return categoria
  }

  public static filtrarPorRota(categoria: string): FiltroTipoLoja {
    return {
      ckbAgencias: categoria == RotaLojasEnum.AGENCIAS,
      ckbCambio: categoria == RotaLojasEnum.CAMBIO,
      ckbCorrespondConsig: categoria == RotaLojasEnum.CONSIGNADO,
      ckbCorrespondVeic: categoria == RotaLojasEnum.VEICULOS,
      ckbCorrespondImob: categoria == RotaLojasEnum.CGI,
      ckbDaypag: categoria == RotaLojasEnum.DAYPAG,
      ckbCredConta: categoria == RotaLojasEnum.CAMBIOSIMPLES,
    };
  }

  public static obterRotaPorRotaLojaEnum(rotaLojaEnum: RotaLojasEnum): string{
    return `${urlAgencias}/${rotaLojaEnum}`
  }
}

export enum RotaLojasEnum {
  AGENCIAS = 'agencias',
  CAMBIO = 'cambio',
  CONSIGNADO = 'consignado',
  VEICULOS = 'veiculos',
  CGI = 'cgi',
  DAYPAG = 'daypag',
  AGENCIAMATRIZ = 'agencia-matriz',
  CAMBIOSIMPLES = 'cambio-simples',
}
