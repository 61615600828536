import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urlFiles } from 'src/environments/app.environment';
import { NotaPesqSatisfacao } from '../models/nota-pesq-satisfacao.model';
import { PesquisaSatisfacaoOuvidoria } from '../models/pesquisa-satisfacao/pesquisa-satisfacao-ouvidoria.model';
import { PesquisaSatisfacaoSacResult } from '../models/pesquisa-satisfacao/pesquisa-satisfacao-sac-result.model';
import { VerificarSePesquisaExisteOuvidoriaSignature } from '../models/pesquisa-satisfacao/verificar-se-pesquisa-existe-ouvidoria-signature.model';
import { PesquisaSatisfacaoSacSignature } from '../models/pesquisa-satisfacao/pesquisa-satisfacao-sac-signature.model';
import { PesquisaSatisfacaoOuvidoriaSignature } from '../models/pesquisa-satisfacao/pesquisa-satisfacao-ouvidoria-signature.model';
import { PesquisaSatisfacaoOuvidoriaResult } from '../models/pesquisa-satisfacao/pesquisa-satisfacao-ouvidoria-result.model';
import { MotivosAvaliacao } from '../models/pesquisa-satisfacao/motivos-avaliacao.model';
import { ListarMotivosAvaliacaoSignature } from '../models/pesquisa-satisfacao/listar-motivos-avaliacao-signature.model';
import { environment } from 'src/environments/environment';
import { urlApiPesquisaSatisfacao, urlApiSac } from 'src/environments/app.api';

@Injectable({
  providedIn: 'root'
})
export class PesquisaSatisfacaoService {

  constructor(
    private http: HttpClient
  ) { }

  incluirPesquisaSatisfacao(signature: PesquisaSatisfacaoSacSignature): Observable<PesquisaSatisfacaoSacResult> {
    return this.http.post<PesquisaSatisfacaoSacResult>(
      `${urlApiSac}/PesquisaSatisfacao/IncluirPesquisaSatisfacao`,
      signature
    );
  }

  verificarSePesquisaOuvidoriaExiste(codDossie: string): Observable<PesquisaSatisfacaoOuvidoria> {
    return this.http.post<PesquisaSatisfacaoOuvidoria>(
      `${urlApiPesquisaSatisfacao}/RespostaPesquisa/VerificarPesquisaExiste`,
      new VerificarSePesquisaExisteOuvidoriaSignature(codDossie)
    );
  }

  incluirPesquisaSatisfacaoOuvidoria(signature: PesquisaSatisfacaoOuvidoriaSignature): Observable<PesquisaSatisfacaoOuvidoriaResult> {
    return this.http.post<PesquisaSatisfacaoOuvidoriaResult>(
      `${urlApiPesquisaSatisfacao}/RespostaPesquisa/IncluirRespostaPesquisa`,
      signature
    );
  }

  listarMotivosAvaliacao(codServicoCategoria: number): Observable<MotivosAvaliacao[]>{
    return this.http.post<MotivosAvaliacao[]>(
      `${urlApiSac}/PesquisaSatisfacao/ListarMotivosAvaliacao`,
      new ListarMotivosAvaliacaoSignature(codServicoCategoria)
    );
  }

  listarBoxPesqSatisfacao(): NotaPesqSatisfacao[] {
    return [
      { Icone: 'sentiment_very_dissatisfied', Nota: '1', Ativo: false },
      { Icone: 'sentiment_dissatisfied', Nota: '2', Ativo: false },
      { Icone: 'sentiment_neutral', Nota: '3', Ativo: false },
      { Icone: 'sentiment_satisfied', Nota: '4', Ativo: false },
      { Icone: 'sentiment_very_satisfied', Nota: '5', Ativo: false }
    ]
  }
}
