import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DayaneHelper } from 'src/app/core/helpers/dayane.helper';
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-aviso-generico-modal',
  templateUrl: './aviso-generico-modal.component.html'
})
export class AvisoGenericoModalComponent implements OnDestroy {
  msg: string

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private modal: MatDialog,
    private _blurService: BlurService,
    private _loadingService: LoadingService
  ) {
    this.msg = data.msg
  }

  ngOnInit(){
    this._blurService.setBlurBackground(true)
    this._loadingService.setLoading(false)
  }

  ngOnDestroy(): void{
    this._loadingService.setLoading(false)
    this._blurService.setBlurBackground(false)
    this.modal.closeAll()
  }

  fechar() {
    this._loadingService.setLoading(false)
    this.modal.closeAll()
  }

  abrirChatbotDayane(){
    DayaneHelper.abrir();
  }
}
