import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BlurService {
  private subject = new Subject<boolean>();

  setBlurBackground(isLoading: boolean) {
    this.subject.next(isLoading)
  }

  getBlurBackground(): Observable<any> {
    return this.subject.asObservable();
  }
}
