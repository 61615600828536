import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TypedJsSettings } from 'src/app/core/models/typed-js-settings.model';
import { urlFiles } from 'src/environments/app.environment';

@Component({
  selector: 'app-dayane-body',
  templateUrl: './dayane-body.component.html',
  styleUrls: ['./dayane-body.component.scss']
})
export class DayaneBodyComponent implements OnInit {
  pathCdnDayane: string = `${urlFiles}/cdn/dayane`
  backgroundUrl: string = `url(${this.pathCdnDayane}/bg.jpg) top center`
  imgDayanePrincipal: string = `${this.pathCdnDayane}/imgDayanePrincipal.png`
  typed: TypedJsSettings

  @Output() irParaContato = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    this.setTypedJsConfig()
  }

  setTypedJsConfig(){
    this.typed = {
      Textos: ['Assistente virtual do Banco Daycoval.', 'Estou aqui para te ajudar.', 'Que tal me conhecer?'],
      VelocidadeDigitacaoEmMilissegundos: 80,
      VelocidadeBackspaceEmMilissegundos: 0,
      FadeOut: true,
      Loop: true
    }
  }
}
