import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { ContatosRedesSociaisEnum } from 'src/app/core/helpers/enums/contatos-redes-sociais.enum';
import { ContatosTelefonesEnum } from 'src/app/core/helpers/enums/contatos-telefones.enum';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { Banner } from 'src/app/core/models/cms/banner.model';
import { urlFiles } from 'src/environments/app.environment';

@Component({
  selector: 'app-footer-contatos',
  templateUrl: './footer-contatos.component.html',
  styleUrls: ['./footer-contatos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterContatosComponent implements OnInit {
  faWhatsapp = faWhatsapp
  faFacebookF = faFacebookF
  faYoutube = faYoutube
  faInstagram = faInstagram
  faLinkedinIn = faLinkedinIn

  linkAPP = LinksUteisEnum.APPDOWNLOARODAPE;
  dayaneWhatsappRedirect = LinksUteisEnum.REDIRECTWHATSAPPDAYANE
  DAYANEWHATSAPP:  ContatosTelefonesEnum= ContatosTelefonesEnum.DAYANEWHATSAPP

  linkFacebook: string = ContatosRedesSociaisEnum.FACEBOOK
  linkYoutube: string = ContatosRedesSociaisEnum.YOUTUBE
  linkLinkedin: string = ContatosRedesSociaisEnum.LINKEDIN
  linkInstagram: string = ContatosRedesSociaisEnum.INSTAGRAM
  redesSociais: Banner[] = [];
  appStore: Banner[] = [];

  urlFiles = urlFiles;

  constructor() {

  }

  ngOnInit(): void {
    this.getImagensAppStore();
  }

  getImagensAppStore() {
    this.appStore = [
      {
        Titulo: 'App Daycoval no Google Play',
        UrlImagemDesktop: 'assets/img/app/android-dark.png',
        SubTitulo: '',
        Link: this.linkAPP,
        TipoLink: 0,
        NomeLink: 'Play Store'
      },
      {
        Titulo: 'App Daycoval na App Store',
        UrlImagemDesktop: 'assets/img/app/apple-dark.png',
        SubTitulo: '',
        Link: this.linkAPP,
        TipoLink: 0,
        NomeLink: 'Apple Store'
      }
    ]
  }
}
