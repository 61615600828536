<section class="pesquisa-satisfacao box">
  <div class="container" *ngIf="!exibirLoading && !result" id="sac">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <strong class="text-left">Queremos saber sua opinião!</strong>
        <strong class="text-left">
          Em uma escala de 01 a 05, qual seu nível de esforço para realizar a solicitação em nosso site.
        </strong>
      </div>
    </div>

    <div class="row my-4">
      <ng-container *ngFor="let item of listaBoxPesqSatisfacao">
        <div class="col">
          <app-nota-pesquisa-satisfacao-box [nota]="item" (selecionarNota)="selecionar($event)"></app-nota-pesquisa-satisfacao-box>
        </div>
      </ng-container>
    </div>

    <form [formGroup]="form" (ngSubmit)="enviar()" class="form-padrao">
      <div class="row" *ngIf="showMotivosAvaliacao">
        <div class="col-md-12">
          <mat-form-field appearance="fill">
            <mat-label>Selecione uma opção</mat-label>
            <mat-select formControlName="motivoAvaliacao" required (selectionChange)="selecionarMotivo($event.value)">
              <mat-option *ngFor="let item of motivosAvaliacao; let i = index" [value]="item.Motivo">{{i+1}} - {{item.Motivo}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showComentario">
        <div class="col-12 form-padrao">
          <mat-form-field appearance="fill">
            <mat-label>Faça um comentário</mat-label>
            <textarea id="comentario" matInput formControlName="comentario" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-primary col-10 col-md-8" [disabled]="!notaSelecionada" (click)="enviar()">ENVIAR</button>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="exibirLoading">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="!exibirLoading && result" [ngClass]="(result.Sucesso) ? 'sucessoPesqSatisfacao' : 'erroPesqSatisfacao'">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <span [innerHTML]="result.Mensagem"></span>
      </div>
    </div>
  </div>
</section>
