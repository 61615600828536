import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DayaneHelper } from 'src/app/core/helpers/dayane.helper';
import { Profile } from 'src/app/core/models/dayane/profile.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent implements OnInit {
  loading = true;
  profile: Profile

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    DayaneHelper.limparConversa()
    this.obterProfile()
    setTimeout(() => this.spinner.show(), 25)
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      DayaneHelper.reinitAssunto(this.profile);
      DayaneHelper.setFullScreen(true);
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 2500);
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      DayaneHelper.removeProfile()
      DayaneHelper.removeFullScreen()
      DayaneHelper.reinit(environment.dayaneAppIdDefault)
      DayaneHelper.limparConversa()
    }
  }

  obterProfile() {
    const nav = this.router.getCurrentNavigation();
    if (nav.extras.state != undefined) {
      this.profile = nav.extras.state.profile;
    } else {
      this.activatedRoute.queryParams.subscribe(params => {
        this.profile = { assunto: 'nps', nome: params.nome, email: params.email, cpf: params.cpf, id: params.id }
      })
    }
  }

}
