<section class="erro-generico modal-generico">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="svg-error"></div>
    </div>
    <div class="row d-flex justify-content-center mt-3" id="titulo">
      <h3 [innerHtml]="msg"></h3>
    </div>
    <div class="row d-flex justify-content-center mt-1" id="mensagem">
      <p class="text-center">
        Pedimos desculpas, mas por motivos internos, não conseguimos realizar
        sua solicitação. Por favor, tente novamente em
        alguns segundos e em breve retornaremos seu contato.
      </p>
    </div>
  </div>
  <div class="row no-space" id="info">
    <div class="container d-flex justify-content-between align-items-center">
      <div class="box-info" id="icone">
        <i class="material-icons">info_outline</i>
      </div>
      <div class="box-info" id="descricao">
        <p class="mb-0">Caso persista alguma dúvida fale com nosso chat, a <a (click)="abrirDayane()">Dayane</a> está pronta para
          ajudá-lo (a)
        </p>
      </div>
    </div>
  </div>

  <i class="material-icons absolute pointer" id="close" (click)="fechar()" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
</section>
