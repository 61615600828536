import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectSitesExternosGuard } from './core/guards/redirect-sites-externos.guard';
import { RedirectComponent } from './modules/landing-page/pages/redirect/redirect.component';
import { PagenotFoundComponent } from './modules/pagenot-found/pagenot-found.component';

const routes: Routes = [
  { path: 'daycoval-investe', pathMatch: 'prefix', redirectTo: 'investimentos/daycoval-investe' },
  { path: 'fundos', pathMatch: 'prefix', redirectTo: 'investimentos/fundos-investimento' },
  { path: 'perguntas-frequentes', pathMatch: 'prefix', redirectTo: 'atendimento/perguntas-frequentes' },
  { path: 'renda-fixa', pathMatch: 'prefix', redirectTo: 'investimentos/renda-fixa' },
  { path: 'sustentabilidade', pathMatch: 'prefix', redirectTo: 'institucional/sustentabilidade' },
  { path: 'simulador', pathMatch: 'prefix', redirectTo: 'investimentos/simulador-investimentos' },
  { path: 'sobre-nos', pathMatch: 'prefix', redirectTo: 'institucional/sobre-nos' },
  { path: 'cartao-credito', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-credito' },
  { path: 'ouvidoria', pathMatch: 'prefix', redirectTo: 'institucional/ouvidoria' },
  { path: 'moedas', pathMatch: 'prefix', redirectTo: 'cambio/moedas' },
  { path: 'principais-indicadores', pathMatch: 'prefix', redirectTo: '/institucional/sobre-nos/principais-indicadores' },
  { path: 'cartao-black', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-credito/cartao-black' },
  { path: 'cartao-platinum', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-credito/cartao-platinum' },
  { path: 'cartao-consignado', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-consignado' },
  { path: 'cartao-beneficio-consignado', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-beneficio-consignado' },
  { path: 'cartao-internacional', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-internacional' },
  { path: 'regulamento-cartoes', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-credito/regulamento-cartoes' },
  { path: 'cartao-credito', pathMatch: 'prefix', redirectTo: 'credito-para-voce/cartao-credito' },
  { path: 'cadastro-positivo', pathMatch: 'prefix', redirectTo: 'servicos/cadastro-positivo' },
  { path: 'institucional/mercado-de-capitais', pathMatch: 'prefix', redirectTo: 'investimentos/mercado-capitais' },
  { path: 'cet', pathMatch: 'prefix', redirectTo: 'institucional/cet' },
  { path: 'espelho', pathMatch: 'prefix', redirectTo: 'institucional/espelho' },
  { path: 'emprestimodigital', pathMatch: 'prefix', redirectTo: '#{EmprestimoDigital}' },
  { path: 'canalcorrespondente', pathMatch: 'prefix', redirectTo: 'canalcorrespondente' },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'atendimento',
    loadChildren: () => import('./modules/atendimento/atendimento.module').then(m => m.AtendimentoModule)
  },
  {
    path: 'cambio',
    loadChildren: () => import('./modules/cambio/cambio.module').then(m => m.CambioModule)
  },
  {
    path: 'investimentos',
    loadChildren: () => import('./modules/investimentos/investimentos.module').then(m => m.InvestimentosModule)
  },
  {
    path: 'institucional',
    loadChildren: () => import('./modules/institucional/institucional.module').then(m => m.InstitucionalModule)
  },
  {
    path: 'credito-para-voce',
    loadChildren: () => import('./modules/credito-para-voce/credito-para-voce.module').then(m => m.CreditoParaVoceModule)
  },
  {
    path: 'credito-para-sua-empresa',
    loadChildren: () => import('./modules/credito-para-sua-empresa/credito-para-sua-empresa.module').then(m => m.CreditoParaSuaEmpresaModule)
  },
  {
    path: 'credito-imobiliario',
    loadChildren: () => import('./modules/credito-imobiliario/credito-imobiliario.module').then(m => m.CreditoImobiliarioModule)
  },
  {
    path: 'servicos',
    loadChildren: () => import('./modules/servicos/servicos.module').then(m => m.ServicosModule)
  },
  {
    path: 'tesouraria',
    loadChildren: () => import('./modules/tesouraria/tesouraria.module').then(m => m.TesourariaModule)
  },
  {
    path: 'calculadoras',
    loadChildren: () => import('./modules/calculadoras/calculadora.module').then(m => m.CalculadoraModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  { path: 'sitemap.xml', redirectTo: '../sitemap.xml' },
  { path: 'asset', pathMatch: 'prefix', canActivate: [RedirectSitesExternosGuard], component: RedirectComponent},
  { path: 'oghy11', pathMatch: 'prefix', canActivate: [RedirectSitesExternosGuard], component: RedirectComponent},
  { path: '**', pathMatch: 'full', component: PagenotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
