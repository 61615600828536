import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { BoxConteudoLinks } from 'src/app/core/models/box-conteudo-links.model';
import { Banner } from 'src/app/core/models/cms/banner.model';
import { Link } from 'src/app/core/models/link.model';
import { TagManagerService } from 'src/app/core/services/tag-manager.service';
import { UrlCanonicaService } from 'src/app/core/services/url-canonica.service';

@Component({
  selector: 'app-pagenot-found',
  templateUrl: './pagenot-found.component.html',
  styleUrls: ['./pagenot-found.component.scss']
})
export class PagenotFoundComponent implements OnInit, OnDestroy {
  appStore: Banner[] = [];
  linkAPP = LinksUteisEnum.APPDOWNLOAD404;
  BoxConteudosLinks: BoxConteudoLinks = new BoxConteudoLinks();

  constructor(private _tagManagerService: TagManagerService, private router: Router, private _urlCanonica: UrlCanonicaService,
    private titleService: Title) { }

  ngOnDestroy(): void {
    this.titleService.setTitle("Portal Daycoval");
  }
  ngOnInit(): void {
    this._urlCanonica.setCanonicalUrl('https://www.daycoval.com.br/404', '404');
    this._tagManagerService.tagManagerPageNotFound('Página não encontrada',  this.router.url);
    this.BoxConteudosLinks.Links = [
      new Link('Portal Daycoval', '/'),
      new Link('Atendimento', '/atendimento'),
      new Link('Empréstimo Consignado', '/credito-para-voce/credito-consignado/emprestimo-consignado'),
      new Link('Financiamento de Veículos', '/credito-para-voce/veiculos'),
      new Link('Internet Banking', 'https://ecode.daycoval.com.br/', true),
      new Link('Empréstimo para empresas', '/credito-para-sua-empresa/creditos/credito-digital')
    ];

    this.getImagensAppStore();
  }

  getImagensAppStore() {
    this.appStore = [
      {
        Titulo: 'Play store',
        UrlImagemDesktop: 'assets/img/app/android-dark.png',
        SubTitulo: '',
        Link: this.linkAPP,
        TipoLink: 0,
        NomeLink: 'Play Store'
      },
      {
        Titulo: 'apple store',
        UrlImagemDesktop: 'assets/img/app/apple-dark.png',
        SubTitulo: '',
        Link: this.linkAPP,
        TipoLink: 0,
        NomeLink: 'Apple Store'
      }
    ]
  }
}
