import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { BlurService } from 'src/app/core/services/blur.service';

@Component({
  selector: 'app-acesse-sua-conta-modal',
  templateUrl: './acesse-sua-conta-modal.component.html',
  styleUrls: ['./acesse-sua-conta-modal.component.scss'],
})
export class AcesseSuaContaModalComponent implements OnInit {
  showModalInicial: boolean = true;
  showTipoConta: boolean = false;
  showContaPessoaFisica: boolean = false;

  constructor(
    public modal: MatDialog,
    private _blurService: BlurService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._blurService.setBlurBackground(false);
  }

  fechar() {
    this.modal.closeAll();
  }

  acessarConta() {
    window.open(LinksUteisEnum.DAYCONNECT, '_blank');
    this.fechar();
  }

  abrirPJDigital() {
    window.open(LinksUteisEnum.PJDIGITAL, '_blank');
    this.fechar();
  }

  abrirPFDigital() {
    window.open(LinksUteisEnum.ABRECONTASPF, '_self');
    this.fechar();
  }

  abrirLPConsig() {
    window.open(LinksUteisEnum.LPCONSIG, '_blank');
    this.fechar();
  }
}
