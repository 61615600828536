<section class="btn-ri" (mouseenter)="exibirLista = true" (mouseleave)="exibirLista = false">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="box-ri relative" >
                    <p class="no-select">{{titulo}}</p>
                    <span class="material-icons absolute" id="expandir">expand_more</span>
                </div>

                <ul class="list-group absolute box-ri expanded" *ngIf="exibirLista" [@toggle]="exibirLista">
                    <li class="list-group-item relative no-select" id="titulo" (click)="exibirLista = !exibirLista">
                        {{titulo}}
                        <span class="material-icons absolute" id="encolher">expand_less</span>
                    </li>
                    <li class="list-group-item center" (click)="abrirRI()">
                        <div class="box-circle pointer" id="link-site-brasil"></div>
                        <p class="no-select" style="margin-top: 14px !important;">Português</p>
                    </li>
                    <li class="list-group-item center" (click)="abrirRIEN()">
                        <div class="box-circle pointer" id="link-site-usa"></div>
                        <p class="no-select" style="margin-top: 14px !important;">Inglês</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
