<section class="termo-aceite-lgpd py-4 bg-light" *ngIf="politicasETermos">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-7">
                <p> Utilizamos cookies para reconhecer dados e melhorar sua experiência, conforme nossa
                    <a [href]="urlPoliticaPrivacidade" target="_blank">"Política de Privacidade e Termos de
                        Uso"</a>
                        
                </p>
                <p><strong>Ao navegar neste site, você declara estar de acordo com essas condições.</strong></p>
            </div>

            <div class="col-12 col-lg-3 center col-btn">
                <input id="btnOKEntendi" class="btn btn-primary" type="button" value="OK, ENTENDI" (click)="aceitarTermo()">
            </div>
        </div>
    </div>
</section>
