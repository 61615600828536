import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { urlAgencias } from 'src/app/core/helpers/lojas.helper';

@Component({
  selector: 'app-icones-navegacao-menu',
  templateUrl: './icones-navegacao-menu.component.html',
  styleUrls: ['./icones-navegacao-menu.component.scss']
})
export class IconesNavegacaoMenuComponent implements OnInit {
  urlAgencias = urlAgencias
  tooltipPosition: TooltipPosition = 'above'
  
  constructor() { }

  ngOnInit(): void {
  }

}
