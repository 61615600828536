export const environment = {
  production: '#{production}',
  urlApiIntegracaoInvestimento: '#{urlApiIntegracaoInvestimento}',
  urlApiInstitucional: '#{urlApiInstitucional}',
  urlApiInstitucionalWebDmz: '#{urlApiInstitucionalWebDmz}',
  urlApiSac: '#{urlApiSac}',
  urlApiLead: '#{urlApiLead}',
  urlApiPrognum: '#{urlApiPrognum}',
  urlDominioSite: '#{urlDominioSite}',
  googleApiKey: '6LcqF3IUAAAAABRAukkHHGtIDnGcsLf1nFe6hLnv',
  urlFiles: '#{urlFiles}',
  urlApiPesquisaSatisfacao: '#{urlApiPesquisaSatisfacao}',
  dayaneAppIdDefault: '#{dayaneAppIdDefault}',
  dayaneChatTitleDefault: '#{dayaneChatTitleDefault}',
  dayaneAppIdOuvidoria: '#{dayaneAppIdOuvidoria}',
  dayaneChatTitleOuvidoria: '#{dayaneChatTitleOuvidoria}',
  dayaneAppIdOuvidoriaEng: '#{dayaneAppIdOuvidoriaEng}',
  AppIdTaNaMaoCreditoGarantiaImovel: 'cf672820-b941-4332-82ae-d89d2f328c76',
  AppIdPortalDaycoval: '#{dayaneAppIdPortalDaycoval}',
  AppIdCampanhaDaycoval:	'#{dayaneAppIdCampanhaDaycoval}',
  AppIdAtendimentoDayane:	'#{dayaneAppIdAtendimentoDayane}',
  AppIdTaNaMaoCartao:	'26fe5136-eaa1-45e8-a7e8-892a912999cf',
  AppIdTaNaMaoCreditoConta:	'b77b0ddc-70b6-4b03-b854-9ac7ac01ad79',
  AppIdTaNaMaoEmprestimo:	'34765cf8-1460-4a70-8b95-e3158b3163b1',

  AppIdNps: '#{dayaneAppIdNps}',
  AppIdCampanhaFGTS: '#{dayaneAppIdCampanhaFGTS}',
  redirectDayconnectCartoes: '#{redirectDayconnectCartoes}'
};
