
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ApiHttp implements HttpInterceptor {
  constructor(
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.StatusCode > 399) {
          throw new HttpErrorResponse({
            error: event.body.ErrorMessage,
            headers: event.headers,
            status: 400,
            statusText: event.statusText,
            url: event.url
          });
        } else {
          if (event.body.SerializedObject == null || event.body.SerializedObject === "") {
            return event.clone({
              body: event.body
            });
          }
          else{
            return event.clone({
              body: JSON.parse(event.body.SerializedObject)
            });
          }
        }
      }
    }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
