<section class="dayane-canais-atendimento">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Canais de Atendimento</h1>
      </div>
      <div class="col-12">
        <h2>Escolha a melhor maneira de falar comigo abaixo.</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="box-canal">
          <div class="icon" id="whatsapp">
            <fa-icon [icon]="faWhatsapp" size="4x"></fa-icon>
          </div>

          <div class="row">
            <div class="col-12">
              <p>
                Adicione o número <strong><a id="numero-dayane"
                    [href]="redirectWhatsapp"
                    target="_blank">{{WHATSAPP}}</a></strong>
                no seu celular ou leia o QrCode abaixo e mande
                um "Oi"
                para iniciar a conversa.</p>
            </div>
            <div class="col-xl-5 pl-xl-0 col-lg-12 col-sm-5 col-12"
              id="img-qrcode">
              <div class="qrcode">
                <img [src]="imgQrCode">
              </div>
            </div>

            <div class="col-xl-7 col-lg-12 col-7" id="img-selo">
              <div class="row">
                <div class="col-12">
                  <img [src]="imgSelo">
                </div>
                <div class="col-12">
                  <small>
                    Ao adicionar nosso número certifique que
                    o selo acima aparece ao lado do nome
                    "Banco Daycoval", isto é a garantia que
                    você está conversando com nossa conta
                    oficial verificada pelo WhatsApp.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="box-canal">
          <div class="icon" id="chat">
            <fa-icon [icon]="faComments" size="4x"></fa-icon>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="description">
                Para falar comigo por aqui mesmo, clique agora
                em
                <strong>"Iniciar Conversa"</strong> para começar
                seu
                atendimento.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn" (click)="abrirDayane()">
                INICIAR CONVERSA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
