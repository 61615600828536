import { ComponentType } from '@angular/cdk/portal';
import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BlurService } from 'src/app/core/services/blur.service';

@Component({
  selector: 'app-link-padrao',
  templateUrl: './link-padrao.component.html',
  styleUrls: ['./link-padrao.component.scss'],
})
export class LinkPadraoComponent implements OnInit {
  @Input() ehLinkExterno: boolean = false;
  @Input() texto: string = ''
  @Input() link: string = ''
  @Input() cor: string = ''
  @Input() corSeta: string = ''
  @Input() corTexto: string = ''
  @Input() fontsize: string = ''
  @Input() fontsizeicon: string = ''
  @Input() fontFamily: string = ''
  @Input() marginBottom: string = ''
  @Input() align: string = ''
  @Input() ehModal: boolean
  @Input() linkTexto: boolean = false
  @Input() componente: ComponentType<any>
  @Input() matDialogConfig: MatDialogConfig

  @Output() onClick = new EventEmitter<any>()
  @Output() botaoModalClicado: EventEmitter<boolean> = new EventEmitter()

  classAlign: string;
  anchorLink: boolean = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private _blurService: BlurService,
    private modal: MatDialog
  ) { }

  ngOnInit(): void {
    this.bindProperties();
    this.verificarSeEhLinkExterno();
  }

  verificarSeEhLinkExterno() {
    if (this.link != undefined && !this.ehLinkExterno) {
      if (this.link.substring(0, 4) == 'http'
       || this.link.substring(0, 7) == 'mailto:') {
        this.ehLinkExterno = true;
      } else {
        this.ehLinkExterno = false;
      }

    } else {
      this.ehLinkExterno = true;
    }
  }

  bindProperties() {
    if (this.link == '' || this.link == undefined) {
      this.link = '#';
    }

    if (this.link.startsWith('#')) {
      this.link = this.link.substring(1);
      this.anchorLink = true;
    }

    this.validarCor();
    this.validarAlinhamento();
    this.validaFont();
  }

  validarCor() {
    const corPrimaria: string = '#0088ca';

    if (this.cor == '' && this.corSeta == '' && this.corTexto == '') {
      this.corSeta = corPrimaria;
      this.corTexto = corPrimaria;
    } else if (this.cor != '') {
      this.corSeta = this.cor;
      this.corTexto = this.cor;
    } else {
      this.corSeta = this.corSeta == '' ? corPrimaria : this.corSeta;
      this.corTexto = this.corTexto == '' ? corPrimaria : this.corTexto;
    }
  }

  validarAlinhamento() {
    switch (this.align) {
      case '':
        this.classAlign =
          'align-items-start mt-1 mt-md-0 align-items-md-center link-bottom';
        break;
      case 'center':
        this.classAlign = 'align-items-center link-center';
        break;
      case 'top':
        this.classAlign = 'align-items-start link-top';
        break;
      case 'bottom':
        this.classAlign = 'align-items-end link-bottom';
        break;
      default:
        console.warn(`LINK-PADRAO: parametro align inválido (${this.align}). Utilize center, top ou bottom.`);
    }
  }

  validaFont() {
    this.fontsize == '' ? (this.fontsize = '18px') : this.fontsize;
    this.fontsizeicon == ''
      ? (this.fontsizeicon = this.fontsize)
      : this.fontsizeicon;
    this.fontFamily == '' ? (this.fontFamily = 'Muli-Bold') : this.fontFamily;
  }

  scrollTo(id: string) {
    this.viewportScroller.scrollToAnchor(id);
  }

  //metodo generico para modais
  abrirModal() {
    if (this.componente != undefined) {
      this._blurService.setBlurBackground(true);
      this.modal.open(this.componente, this.matDialogConfig);
    }
    this.botaoModalClicado.emit(true);
  }
}
