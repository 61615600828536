import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { BlurService } from 'src/app/core/services/blur.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { urlFiles } from 'src/environments/app.environment';

@Component({
  selector: 'app-dayane-header',
  templateUrl: './dayane-header.component.html',
  styleUrls: ['./dayane-header.component.scss']
})
export class DayaneHeaderComponent implements OnInit {
  fixarHeader: boolean = false
  logoUrl: string = `${urlFiles}/cdn/dayane/logo-daycoval.png`
  ABRECONTAS = LinksUteisEnum.ABRECONTASPF

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _blurService: BlurService,
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getScreenSize()
  }

  @HostListener('window:scroll', [])
  getScreenSize() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.scrollY > 100) {
        this.fixarHeader = true
      }
      else {
        this.fixarHeader = false
      }
    }
  }

  abraSuaConta() {
    this._blurService.setBlurBackground(true);
    this._modalService.abrirModalAcesseSuaConta();
  }
}
