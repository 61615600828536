<section class="sucesso-modal modal-generico">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 d-flex justify-content-center">
        <div class="svg-confirm"></div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-3" id="titulo">
      <div class="col-12">
        <h3>{{titulo}}</h3>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-1" id="mensagem" *ngIf="msg">
      <div class="col-12">
        <h4 class="text-center" [innerHTML]="msg"></h4>
      </div>
    </div>

    <app-pesquisa-satisfacao *ngIf="codSolicitacao" [codSolicitacao]="codSolicitacao"></app-pesquisa-satisfacao>
  </div>

  <div class="row no-space" id="info">
    <div class="container d-flex justify-content-between align-items-center">
      <div class="box-info" id="icone">
        <i class="material-icons info-outline">info_outline</i>
      </div>
      <div class="box-info" id="descricao">
        <p class="mb-0">Estamos à disposição para esclarecimentos através do nosso Chat, a
          <a [href]="dayaneRedirect" target="_blank">Dayane</a> está
          pronta para ajudá-lo (a)
        </p>
      </div>
    </div>
  </div>

  <i class="material-icons absolute pointer" id="close" (click)="fechar()" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
</section>
