import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotaPesqSatisfacao } from 'src/app/core/models/nota-pesq-satisfacao.model';
import { MotivosAvaliacao } from 'src/app/core/models/pesquisa-satisfacao/motivos-avaliacao.model';
import { PesquisaSatisfacaoSacResult } from 'src/app/core/models/pesquisa-satisfacao/pesquisa-satisfacao-sac-result.model';
import { PesquisaSatisfacaoSacSignature } from 'src/app/core/models/pesquisa-satisfacao/pesquisa-satisfacao-sac-signature.model';
import { PesquisaSatisfacaoService } from 'src/app/core/services/pesquisa-satisfacao.service';

@Component({
  selector: 'app-pesquisa-satisfacao',
  templateUrl: './pesquisa-satisfacao.component.html',
  styleUrls: ['../pesquisa-satisfacao.scss']
})
export class PesquisaSatisfacaoComponent implements OnInit, OnDestroy {
  @Input() codSolicitacao: number

  showMotivosAvaliacao: boolean = false
  showComentario: boolean = true
  listaBoxPesqSatisfacao: NotaPesqSatisfacao[]
  comentario: string
  notaSelecionada: string
  result: PesquisaSatisfacaoSacResult
  exibirLoading: boolean = false
  motivosAvaliacao: MotivosAvaliacao[]
  form: FormGroup

  constructor(
    private _pesqSatisfacaoService: PesquisaSatisfacaoService
  ) { }

  ngOnInit(): void {
    this.listaBoxPesqSatisfacao = this._pesqSatisfacaoService.listarBoxPesqSatisfacao()
    this.form = new FormGroup({
      comentario: new FormControl('', { validators: [Validators.maxLength(250)] })
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('codServCateg')
  }

  selecionar(nota: NotaPesqSatisfacao) {
    this.listaBoxPesqSatisfacao.forEach(el => {
      el.Ativo = el != nota ? false : true
    })

    this.notaSelecionada = nota.Nota

    this.form.addControl('comentario', new FormControl('', { validators: [Validators.maxLength(250)] }))
    this.showComentario = true
    this.showMotivosAvaliacao = false
    this.form.removeControl('motivoAvaliacao')

    //PESQUISA NPS FOI DESABILITADA TEMPORARIAMENTE POR SOLICITAÇÃO DA ÁREA
    // if (parseInt(nota.Nota) < 4) {
    //   const codServicoCategoria = JSON.parse(localStorage.getItem('codServCateg'))
    //   this._pesqSatisfacaoService.listarMotivosAvaliacao(codServicoCategoria).subscribe(sub => {
    //     if (sub.length == 0) {
    //       this.showComentario = true
    //     }
    //     else {
    //       this.form.addControl('motivoAvaliacao', new FormControl('', { validators: [Validators.required] }))
    //       this.motivosAvaliacao = sub
    //       this.showMotivosAvaliacao = true
    //       this.showComentario = false
    //       this.form.removeControl('comentario')
    //     }
    //   })
    // }
    // else {
    //   this.form.addControl('comentario', new FormControl('', { validators: [Validators.maxLength(250)] }))
    //   this.showComentario = true
    //   this.showMotivosAvaliacao = false
    //   this.form.removeControl('motivoAvaliacao')
    // }
  }

  enviar() {
    this.exibirLoading = true
    const f = this.form.value
    this._pesqSatisfacaoService.incluirPesquisaSatisfacao(new PesquisaSatisfacaoSacSignature(this.codSolicitacao, this.notaSelecionada, f.comentario, f.motivoAvaliacao))
      .subscribe(sub => {
        this.result = sub
      }).add(() => this.exibirLoading = false)
  }

  selecionarMotivo(motivo: any) {
    if (motivo.toLowerCase() == "outros") {
      this.form.addControl('comentario', new FormControl('', { validators: [Validators.required] }))
      this.showComentario = true
    }
    else {
      this.showComentario = false
      this.form.removeControl('comentario')
    }
  }
}
