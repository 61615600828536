<section class="icones-navegacao-menu">
    <div class="row">
        <div class="col-3 col-icone">
            <div id="link-home" class="center icone-link-rapido">
                <span matTooltip="Ir para home">
                    <a class="d-flex"
                        routerLink='/home'>
                        <i class="material-icons">home</i>
                    </a>
                </span>
            </div>
        </div>

        <div class="col-3 col-icone">
            <div id="link-location" class="center icone-link-rapido">
                <span matTooltip="Agências e Correspondentes">
                    <a class="d-flex" [routerLink]="urlAgencias">
                        <i class="material-icons">place</i>
                    </a>
                </span>
            </div>
        </div>

        <div class="col-3 col-icone">
            <div id="link-search" class="center icone-link-rapido">
                <span matTooltip="Perguntas Frequentes">
                    <a class="d-flex"
                        routerLink='/atendimento/perguntas-frequentes'>
                        <i class="material-icons">search</i>
                    </a>
                </span>
            </div>
        </div>
    </div>
</section>
