<section class="page-not-found d-flex flex-column align-items-center justify-content-center">
  <div class="conteudo-not-found d-flex flex-column justify-content-center">
    <header class="mb-3">
      <h1>404</h1>
      <div class="linha-404 mb-3"></div>
      <h2>Página não encontrada.</h2>
      <h3>Veja algumas das páginas mais buscadas do Banco Daycoval.</h3>
    </header>
    <div class="d-flex flex-wrap align-items-end justify-content-between">
      <ng-container>
        <div class="col-md-6 col-12 p-0">
          <ng-container *ngFor="let item of BoxConteudosLinks.Links">
            <div class="col-12 p-0 mb-2">
              <app-link-padrao
                *ngIf="!item.EhLinkExterno"
                [texto]="item.Descricao"
                [link]="item.Link"
                [cor]="'#0088ca'"
                [fontFamily]="'Muli-Bold'"
              ></app-link-padrao>
              <app-link-padrao
                *ngIf="item.EhLinkExterno"
                [texto]="item.Descricao"
                [link]="item.Link"
                [cor]="'#0088ca'"
                [fontFamily]="'Muli-Bold'"
                [ehLinkExterno]="item.EhLinkExterno"
            ></app-link-padrao>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="mt-4 mb-2" id="info-app">
        <p>Baixe nosso aplicativo</p>
        <div class="d-flex flex-wrap ">
          <ng-container *ngFor="let item of appStore">
            <a
              [href]="item?.Link"
              target="_blank"
              class="mr-2 mr-md-4 d-md-block mt-2">
              <img class="" [src]="item?.UrlImagemDesktop" />
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
