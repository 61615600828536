export { }

declare global {
  interface Number {
    toCurrency(): number;
  }

  interface String {
    toCnpj(): string
    toCpf(): string
    toCep(): string
  }
}

if (!Number.prototype.toCurrency) {
  Number.prototype.toCurrency = function (): number {
    var re = '\\d(?=(\\d{' + 3 + '})+' + ('\\D') + ')',
      num = this.toFixed(Math.max(0, ~~2));
   
    return (num.toString().includes(',')  ? num.replace('.', ',') : num).replace(new RegExp(re, 'g'), '$&' + ('.'));
  }
}

if (!String.prototype.toCnpj) {
  String.prototype.toCnpj = function (): string {
    return this.padStart(14, '0')
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }
}

if (!String.prototype.toCep) {
  String.prototype.toCep = function (): string {
    return `${this.slice(0,5)}-${this.slice(5)}`
  }
}

if (!String.prototype.toCpf){
  String.prototype.toCpf = function(): string{
    return this.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
  }
}
