<section class="dayane-body"
  [style.background]="backgroundUrl">
  <div class="container margin-mobile">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="row" id="titulo">
          <div class="col-12">
            <h1 id="ola-dayane">Olá, sou a Dayane.</h1>
          </div>
        </div>
        <div class="row" id="typed">
          <div class="col-12 typewrap" *ngIf="typed">
            <ngx-typed-js [strings]="typed.Textos"
              [typeSpeed]="typed.VelocidadeDigitacaoEmMilissegundos"
              [backSpeed]="typed.VelocidadeBackspaceEmMilissegundos"
              [loop]="typed.Loop" [fadeOut]="typed.FadeOut">
              <span id="texto-digitado" class="typing"></span>
            </ngx-typed-js>
          </div>
        </div>
        <div class="row" id="falar-com-dayane">
          <div class="col-12">
            <h2>
              Eu posso ajudar você a resolver suas dúvidas, conversar
              sobre investimentos, abertura de conta, crédito
              consignado, financiamento de veículos, segunda via de
              boleto e muito mais.
            </h2>
          </div>
          <div class="col-12">
            <button class="btn" (click)="irParaContato.emit()">
              FALAR COM A DAYANE
            </button>
          </div>
        </div>
      </div>
      <div class="offset-lg-1 col-lg-5 col-12 imagem-dayane">
        <img [src]="imgDayanePrincipal" alt="Dayane">
      </div>
    </div>
  </div>
</section>
