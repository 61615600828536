<section class="dayane-header">
    <header id="header" [ngClass]="(fixarHeader) ? 'header-fixed' : ''">
        <div class="container">
            <div id="logo">
                <a routerLink="/home">
                    <img [src]="logoUrl" alt="Logo Daycoval" title="Banco Daycoval" />
                </a>
            </div>
            <nav id="nav-menu-container">
                <ul class="nav-menu">
                    <li id="abra-sua-conta">
                        <a (click)="abraSuaConta()">ABRA SUA CONTA</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</section>