<section class="acesse-sua-conta-modal relative">
  <div class="container" id="modal-inicial" *ngIf="showModalInicial">
    <div class="row">
      <div class="col-12 col-md-6 bg-white" id="ja-tenho-conta">
        <div class="container relative">
          <div class="row">
            <h1>Já tenho conta</h1>
          </div>
          <div class="row mt-3">
            <p>
              Investir é multiplicar. Aproveite os benefícios do Dayconnect
            </p>
          </div>
          <div class="row">
            <button class="btn btn-primary d-flex justify-content-center
              absolute" (click)="acessarConta()">ACESSE SUA
              CONTA
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 bg-primary" id="nao-tenho-conta">
        <div class="container relative">
          <div class="row">
            <h1>Ainda não tenho conta</h1>
          </div>
          <div class="row mt-3">
            <p>
              Simule e confira nosso portfólio com diversas opções de
              investimento para todos os perfis.
            </p>
          </div>
          <div class="row">
            <button class="btn btn-outline-primary absolute d-flex
              justify-content-center absolute"
              (click)="showTipoConta= true; showModalInicial= false">
              ABRA SUA CONTA
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container bg-primary relative" id="tipo-conta"
    *ngIf="showTipoConta">
    <div class="row">
      <div class="col-12">
        <h1 class="text-white w70 mt-3">Selecione o tipo de conta que deseja
          abrir</h1>
      </div>
      <div class="row mt-3 container">
        <div class="col-12">
          <p class="text-white">
            Abra agora sua conta no Banco Daycoval, é simples, rápido e seguro.
            Aproveite todos os benefícios para você
            e para sua empresa.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <button class="btn btn-outline-primary d-flex justify-content-start
          btn-conta" id="btn-acesse-sua-conta"
          (click)="showTipoConta= false; showContaPessoaFisica= true">
          <span class="svg-user mr-3"></span>
          CONTA PARA VOCÊ
        </button>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <button class="btn btn-outline-primary d-flex justify-content-start
          btn-conta" id="btn-acesse-sua-conta"
          (click)="abrirPJDigital()">
          <span class="svg-business mr-3"></span>
          CONTA PARA SUA EMPRESA
        </button>
      </div>
    </div>

    <div class="center absolute" id="link-voltar">
      <i class="material-icons">arrow_backward</i>
      <a (click)="showTipoConta= false; showModalInicial= true">Voltar</a>
    </div>
  </div>

  <div class="container bg-primary relative" id="conta-pessoa-fisica"
    *ngIf="showContaPessoaFisica">
    <div class="row mt-4" id="titulo">
      <div class="col-12 d-flex justify-content-start">
        <p class="d-flex justify-content-center">PESSOA FÍSICA <span
            class="svg-user center ml-2"></span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1 class="text-white">Para qual finalidade deseja abrir sua conta?</h1>
      </div>
      <div class="col-md-6 col-12 mt-3">
        <button class="btn btn-outline-primary d-flex justify-content-center"
          id="btn-acesse-sua-conta"
          (click)="abrirLPConsig()">
          EMPRÉSTIMO CONSIGNADO
        </button>
      </div>
      <div class="col-md-6 col-12 mt-3">
        <button class="btn btn-outline-primary d-flex justify-content-center"
          id="btn-acesse-sua-conta"
          (click)="abrirPFDigital()">
          ABRIR CONTA DE INVESTIMENTOS
        </button>
      </div>
    </div>

    <div class="center absolute" id="link-voltar">
      <i class="material-icons">arrow_backward</i>
      <a (click)="showContaPessoaFisica= false; showTipoConta= true">Voltar</a>
    </div>
  </div>

  <i class="material-icons absolute pointer" id="close" (click)="fechar()"
    [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
</section>
