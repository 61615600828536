import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RedirectGuard } from "src/app/core/guards/redirect.guard";
import { AtendimentoDayaneComponent } from "./pages/atendimento-dayane/atendimento-dayane.component";
import { CampanhaDaycovalComponent } from "./pages/campanha-daycoval/campanha-daycoval.component";
import { DayaneHomeComponent } from "./pages/dayane-home/dayane-home.component";
import { ManutencaoComponent } from "./pages/manutencao/manutencao.component";
import { NpsComponent } from "./pages/nps/nps.component";
import { PesquisaNpsComponent } from "./pages/pesquisa-nps/pesquisa-nps.component";
import { RedirectComponent } from "./pages/redirect/redirect.component";

const routes: Routes = [
  { path: 'apps', canActivate: [RedirectGuard], component: RedirectComponent },
  { path: 'atendimentodayane', pathMatch: 'prefix', component: AtendimentoDayaneComponent },
  { path: 'redirect', pathMatch: 'prefix', component: RedirectComponent },
  { path: 'dayane', pathMatch: 'prefix', component: DayaneHomeComponent },
  { path: 'nps', pathMatch: 'prefix', component: NpsComponent },
  { path: 'pesquisanps', pathMatch: 'prefix', component: PesquisaNpsComponent },
  { path: 'campanhadaycoval', pathMatch: 'prefix', component: CampanhaDaycovalComponent },
  { path: 'manutencao', pathMatch: 'prefix', component: ManutencaoComponent }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
