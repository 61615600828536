import { EventEmitter } from "@angular/core";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
const limit: number = 30
const env = environment

export const imagemDayaneChatbot = 'https://oda.daycoval.com.br:500/Institucional/Dayane/images/DayaneRound.png'
export class DayaneHelper {
  private static dayaneVerificada: EventEmitter<boolean> = new EventEmitter();
  public static retry: number = 0;
  public static dayaneReiniciada: EventEmitter<boolean> = new EventEmitter();

  public static reinit(appId?: string, chatTitle?: string) {
    this.verificarDayaneExiste();
    this.dayaneVerificada.pipe(take(1)).subscribe(() => {
      this.reinitIniciado(appId, chatTitle);
    });
  }

  public static reinitAssunto(profile?: any) {
    this.verificarDayaneExiste();
    this.dayaneVerificada.pipe(take(1)).subscribe(() => {
      this.setProfile(profile)
      this.reinitIniciado(this.validaAssuntoAppId(profile?.assunto));
    });
  }

  public static setOpenChatOnLoad(value: boolean) {
    window['initProps'].openChatOnLoad = value
  }

  public static removeOpenChatOnLoad() {
    if (window["initProps"].openChatOnLoad != undefined)
      delete window['initProps'].openChatOnLoad
  }

  public static abrir() {
    let element = document.getElementsByClassName('oda-chat-button')[0];
    if (element instanceof HTMLElement) {
      element.click();
    }
  }

  public static abrirEmOutraAba(assunto?: string) {
    if (assunto)
      window.open('https://www.daycoval.com.br/AtendimentoDayane?Assunto=' + assunto, '_blank')
    else
      window.open('https://www.daycoval.com.br/AtendimentoDayane', '_blank')
  }

  public static setProfile(profile: any) {
    if (profile != undefined) {
      window["dayaneSettings"].properties = {
        profile: profile
      }
    }
  }

  public static validaAssuntoAppId(assunto: string): string {
    let appId: string;

    switch (assunto.toLowerCase()) {
      case "previdencia":
        appId = env.AppIdPortalDaycoval
        break
      case "refinanciarcontratar":
        appId = env.AppIdPortalDaycoval
        break
      case "saldodevedor":
        appId = env.AppIdPortalDaycoval
        break
      case "tanamaoemprestimoconsig":
        appId = env.AppIdTaNaMaoEmprestimo
        break
      case "tanamaoemprestimoconsiginss":
        appId = env.AppIdTaNaMaoEmprestimo
        break
      case "tanamaocartaoconsig":
        appId = env.AppIdTaNaMaoCartao
        break
      case "tanamaocreditoconta":
        appId = env.AppIdTaNaMaoCreditoConta
        break
      case "tanamaocgi":
        appId = env.AppIdTaNaMaoCreditoGarantiaImovel
        break
      case "nps":
        appId = env.AppIdNps
        break
      case "campanhafgts":
        appId = env.AppIdCampanhaFGTS
        break
        case "acompanhe sua proposta":
          appId = env.dayaneAppIdDefault
          break
      case "campanhadaycoval":
        appId = env.AppIdCampanhaDaycoval
        break
      case "imobiliario":
          appId = env.dayaneAppIdDefault
          break

      default:
        appId = env.dayaneAppIdDefault
    }

    return appId;
  }

  public static removeProfile() {
    if (window["dayaneSettings"].properties != undefined)
      delete window["dayaneSettings"].properties
  }

  public static setFullScreen(value: boolean) {
    window["dayaneSettings"].useFullScreen = value
  }

  public static removeFullScreen() {
    if (window["dayaneSettings"].useFullScreen != undefined) {
      delete window["dayaneSettings"].useFullScreen;
      delete window["initProps"].initUserProfile
    }
  }

  public static limparConversa() {
    localStorage.clear()
  }

  //#region Métodos Privados
  private static setAppId(appId?: string) {
    if (appId != undefined) {
      window["dayaneSettings"].appId = appId;
    }
  }

  private static setChatTitle(chatTitle?: string) {
    if (chatTitle != undefined) {
      window["dayaneSettings"].initProps =
      {
        headerText: chatTitle
      };
    }
  }

  private static setPropGeral(appId?: string, chatTitle?: string) {
    if (appId != undefined) {
      let initProps = window['initProps']
      let getClientId = window["getClientId"]
      if (window['initProps'] != undefined) {
        if (initProps.appId != appId) {
          initProps.appId = appId
          initProps.channelId = appId
          initProps.userId = getClientId()
        }
        if (initProps.headerText != chatTitle) {
          initProps.headerText = chatTitle != undefined ? chatTitle : environment.dayaneChatTitleDefault
        }
      }
    }
  }

  private static setPropPorIdioma() {
    let emIngles = window['emIngles'];
    window['data'] = {};
    window['query'] = location.search.slice(1);
    window['partes'] = window['query'].split('&');
    window['partes'].forEach(function (parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      window['data'][chave] = valor;
    });
    if (emIngles()) {
      this.setDayaneEng()
    } else {
      this.setDayanePt()
    }
  }

  private static setDayaneEng() {
    let initProps = window['initProps'];
    let dayaneSettings = window['dayaneSettings'];

    initProps.locale = 'en-us';
    initProps.sendIcon = dayaneSettings.cdnPath + '/images/btsend.png';
    initProps["i18n"] = {
      "en-us": {
        previousChats: 'Previous conversations',
        close: 'Close widget',
        inputPlaceholder: 'Type a message...',
        attachmentAudioFallback: '',
        clear: 'Clear conversation',
        closing: 'Closing',
        connected: 'Connected',
        connecting: 'Connecting',
        disconnected: "Disconnected",
        retryMessage: 'Try again	',
        send: 'Send message',
        shareFailureMessage: 'Sorry, sharing is not available on this device.',
        shareAudio: 'Share Audio',
        shareFile: 'Share File',
        shareLocation: 'Share Location',
        shareVisual: 'Share Image/Video',
      }
    };

    if (dayaneSettings.initProps) {
      if (dayaneSettings.initProps && dayaneSettings.initProps.headerText) {
        initProps["i18n"]["en-us"].chatTitle = dayaneSettings.initProps.headerText;
      }
    }
  }

  private static setDayanePt() {
    let initProps = window['initProps'];
    let dayaneSettings = window['dayaneSettings'];
    initProps.locale = 'pt-BR';
    initProps.sendIcon = dayaneSettings.cdnPath + '/images/btnenviar.png';
    initProps["i18n"] = {
      "pt-BR": {
        previousChats: 'Mensagens anteriores',
        chatTitle: initProps.headerText != undefined ? initProps.headerText : environment.dayaneChatTitleDefault,
        close: 'Fechar',
        inputPlaceholder: 'Escreva sua mensagem...',
        attachmentAudioFallback: '',
        clear: 'Limpar',
        closing: 'Fechando',
        connected: 'Conectado',
        connecting: 'Conectando',
        disconnected: "Desconectado",
        retryMessage: 'Tente novamente',
        send: 'Enviar',
        shareFailureMessage: 'Desculpe, o compartilhamento nÃ£o estÃ¡ disponÃ­vel neste dispositivo.',
        shareAudio: 'Enviar Audio',
        shareFile: 'Enviar Arquivo',
        shareLocation: 'Enviar localizaÃ§Ã£o',
        shareVisual: 'Enviar Video ou Imagem',
      }
    }

    if (dayaneSettings.initProps) {
      if (dayaneSettings.initProps && dayaneSettings.initProps.headerText) {
        initProps["i18n"]["pt-BR"].chatTitle = dayaneSettings.initProps.headerText;
      }
    }
  }

  private static destroy() {
    if (document.querySelector('#custom-header'))
      document.querySelector('#custom-header').parentElement.remove()
    if (document.querySelector('#fullHeader'))
      document.querySelector('#fullHeader').parentElement.remove()
    if (document.querySelector('link[href$="custom-ui.css"]'))
      document.querySelector('link[href$="custom-ui.css"]').remove()
    if (document.querySelector('link[href$="full-screen.css"]'))
      document.querySelector('link[href$="full-screen.css"]').remove()


    window['Bots'].destroy();
  }

  private static reinitIniciado(appId?: string, chatTitle?: string) {
    this.setAppId(appId);
    this.setChatTitle(chatTitle);
    this.destroy();
    this.setPropGeral(appId, chatTitle);
    this.setPropPorIdioma();
    setTimeout(() => {
      window['initSdk']('Bots');
      this.reinitFinalizado();
    }, 500);
  }

  private static verificarDayaneExiste() {
    let inntervalAtivo = setInterval(() => {
      if (window['Bots'] != undefined || this.retry == limit) {
        clearInterval(inntervalAtivo);
        this.retry = 0;
        this.dayaneVerificada.emit(true);
      }
      this.retry++
    }, 500);
  }

  private static reinitFinalizado() {
    this.verificarDayaneExiste();
    this.dayaneVerificada.pipe(take(1)).subscribe(sub => {
      this.dayaneReiniciada.emit(true);
    });
  }
  //#endregion
}
