import { Component, OnInit } from '@angular/core';
import { urlFiles } from 'src/environments/app.environment';

@Component({
  selector: 'app-dayane-footer',
  templateUrl: './dayane-footer.component.html',
  styleUrls: ['./dayane-footer.component.scss']
})
export class DayaneFooterComponent implements OnInit {
  urlLogoFooter: string = `${urlFiles}/cdn/dayane/logo-footer.png`
  anoAtual: number = new Date().getFullYear();

  constructor(

  ) { }

  ngOnInit(): void {
  }
}
