import { Link } from "./link.model";
import { Conteudo } from "./conteudo.model";

export class BoxConteudoLinks {
  Titulo: string
  Subtitulo: string
  Links?: Link[]
  Conteudo?: Conteudo[]
  Texto?: string
}
