<section class="modal-cet-espelho">
  <div class="container">
    <i class="material-icons absolute pointer" id="close" (click)="fechar()" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
    <div class="row d-flex justify-content-center align-items-center mt-3">
      <h1 class="titulo">{{titulo}}</h1>
    </div>
    <div class="row d-flex mt-2 mb-4" id="mensagem">
      <p>
        Seu documento está pronto!
        O bloqueador de pop-up está ativado em seu navegador. Clique em "Download"
        para baixar o documento.
      </p>
    </div>
    <div class="col-12 mb-4 d-flex justify-content-center">
      <button type="submit" (click)="baixarDocumento()" class="btn btn-primary dark btn-block text-uppercase">
        DOWNLOAD
      </button>
    </div>
  </div>
</section>
