import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LinksUteisEnum } from '../helpers/enums/links-uteis.enum';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (window.location.pathname == '/apps') {
      const os: string = this.deviceDetectorService.getDeviceInfo().os.toLowerCase()
      switch (os) {
        case 'ios':
          window.location.href = LinksUteisEnum.APPSTORE
          break
        default:
          window.location.href = LinksUteisEnum.PLAYSTORE
          break
      }
    }

    return true;
  }
}
