import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DayaneAssuntoRedirectHelper } from 'src/app/core/helpers/dayane-assunto-redirect.helper';
import { DayaneHelper } from 'src/app/core/helpers/dayane.helper';
import { BlurService } from 'src/app/core/services/blur.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-erro-generico-modal',
  templateUrl: './erro-generico-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ErroGenericoModalComponent implements OnDestroy {
  dayaneRedirect: string = DayaneAssuntoRedirectHelper.ObterAtendimento()
  msg: string = "Ops, houve um erro em sua solicitação."

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private modal: MatDialog,
    private _blurService: BlurService,
    private _loadingService: LoadingService
  ) {
    if (data)
      this.msg = data.msg
  }

  ngOnDestroy(): void {
    this._blurService.setBlurBackground(false)
    this._loadingService.setLoading(false)
    this.modal.closeAll()
  }

  fechar() {
    this._loadingService.setLoading(false)
    this.modal.closeAll()
  }

  abrirDayane(){
    DayaneHelper.abrir();
  }
}
