<section class="aviso-generico modal-generico">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="svg-exclamation"></div>
    </div>
    <div class="row d-flex justify-content-center mt-4">
      <h3 [innerHtml]="msg"></h3>
    </div>
  </div>

  <div class="row no-space" id="info">
    <div class="container d-flex justify-content-between align-items-center">
      <div class="box-info" id="icone">
        <i class="material-icons">info_outline</i>
      </div>
      <div class="box-info" id="descricao">
        <p class="mb-0">Caso tenha alguma dúvida, fale com nosso chat, a <a (click)="abrirChatbotDayane()">Dayane</a> está pronta para ajudá-lo (a)
        </p>
      </div>
    </div>
  </div>

  <i class="material-icons absolute pointer" id="close" (click)="fechar()" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i>
</section>
