import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { BlurService } from 'src/app/core/services/blur.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { BtnRiComponent } from './btn-ri/btn-ri.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-botoes-navegacao-menu',
  templateUrl: './botoes-navegacao-menu.component.html',
  styleUrls: ['./botoes-navegacao-menu.component.scss']
})
export class BotoesNavegacaoMenuComponent implements OnInit {
  @ViewChild('btnRi') btnRi: BtnRiComponent

  constructor(
    private _blurService: BlurService,
    private _modalService: ModalService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
  }

  abrirModalAbraSuaConta() {
    this._blurService.setBlurBackground(true);
    this._modalService.abrirModalAcesseSuaConta();
  }

  abrirConta() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(LinksUteisEnum.DAYCONNECT, '_blank');
    }
  }

  abrirConsignado() {
    window.open(`${environment.urlDominioSite}credito-para-voce/credito-consignado/simulador-credito-consignado`, '_blank', 'noopener');
  }
}
