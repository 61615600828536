import { Component, OnInit } from '@angular/core';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { CookieService } from 'src/app/core/services/cookie.service';
const cookie: string = 'DayInstitucionalLGPDCookie'
const periodoCookieAtivo: number = 365

@Component({
  selector: 'app-termo-aceite-lgpd',
  templateUrl: './termo-aceite-lgpd.component.html',
  styleUrls: ['./termo-aceite-lgpd.component.scss']
})
export class TermoAceiteLgpdComponent implements OnInit {
  cookieValue: string;
  politicasETermos: boolean = true;
  urlPoliticaPrivacidade:string = LinksUteisEnum.RIPOLITICAPRIVACIDADE;

  constructor(
    private cookieService: CookieService
  ) {

  }

  ngOnInit(): void {
    if (this.cookieService.check(cookie)) {
      this.politicasETermos = false
    }
  }

  aceitarTermo() {
    this.cookieService.set(cookie, cookie, periodoCookieAtivo, '/', null, true);
    this.politicasETermos = false;
  }
}
