export class PesquisaSatisfacaoSacSignature {
  constructor(
    codSolicitacao: number,
    nota: string,
    comentario?: string,
    motivoAvaliacao?: string
  ) {
    this.CodSolicitacao = codSolicitacao
    this.Nota = nota
    this.Comentario = comentario
    this.MotivoAvaliacao = motivoAvaliacao
  }
  CodSolicitacao: number
  Nota: string
  Comentario: string
  MotivoAvaliacao: string
}
